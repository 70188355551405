import * as React from "react";
import { dispatchError } from "../../common/fx";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Stack, Button, TextField, Grid } from "@mui/material";
import Summary from "./summary";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UIContext, UIState } from "../../providers/UIProvider";
import { isEmpty, isNil, isString, omit } from "lodash";
import {
  changeOrderOptions,
  downloadChangeOrderPDF,
  modifyOptions,
} from "../../apiCalls";
import PrepareChangeOrder from "../../modals/PrepareChangeOrder/PrepareChangeOrder";
import { LoadingButton } from "@mui/lab";
import useAccessControl from "../../hooks/useAccessControl";

const theme = createTheme();

export default function Confirm() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let intentionId = searchParams.get("intentionId");
  const DownloadChangeOrderAccess = useAccessControl(
    "ChangeOrder",
    "DownloadPDF"
  );
  const [state, dispatch] = React.useContext<UIState | any>(UIContext);
  const [downloading, setDownloading] = React.useState<boolean>(false);

  const Submission = () => {
    modifyOptions(
      intentionId as string,
      {
        options: state.changeOrder.pendingItems
          ? state.changeOrder.pendingItems
              .filter((obj: changeOrderOptions) => !isEmpty(obj.description))
              .map((obj: changeOrderOptions) => {
                const newObj = { ...obj };
                newObj.highlandCategory = obj.highlandCategory.key;
                newObj.salesPrice = (
                  newObj.quantity * newObj.unitPrice
                ).toFixed(2);
                return newObj.isNew ? omit(newObj, "id") : newObj;
              })
          : null,
      },
      (res: any) => console.log(res),
      (err: any) =>
        dispatch(
          dispatchError({
            message: err.message,
            statusText: err.response.statusText,
            title: err.response.data.title,
            status: err.response.status,
            detail: err.response.data.detail,
            data: err.response.data,
          })
        )
    );
  };

  const handleDownloadItems = () => {
    setDownloading(true);

    if (intentionId) {
      DownloadChangeOrderAccess &&
        downloadChangeOrderPDF(
          { changeOrderId: intentionId },
          async (res: {
            fileData: "string";
            contentType: "string";
            blobName: "string";
          }) => {
            function base64ToBlob(
              base64: string,
              contentType: string = ""
            ): Blob {
              // Convert Base64 to a byte array
              const byteCharacters = atob(base64);
              const byteArrays = [];

              for (
                let offset = 0;
                offset < byteCharacters.length;
                offset += 512
              ) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);

                for (let i = 0; i < slice.length; i++) {
                  byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
              }

              // Create a blob from the byte array
              return new Blob(byteArrays, { type: contentType });
            }

            function downloadPDF(
              blobName: string,
              contentType: string,
              fileData: string
            ) {
              // Convert Base64 fileData to Blob
              const blob = base64ToBlob(fileData, contentType);

              // Create a Blob URL
              const blobUrl = window.URL.createObjectURL(blob);

              // Create a link element
              const link = document.createElement("a");

              // Set the download attribute with a filename
              link.download = blobName;

              // Set the href to the blob URL
              link.href = blobUrl;

              // Append the link to the document body
              document.body.appendChild(link);

              // Programmatically click the link to trigger the download
              link.click();

              // Clean-up: remove the link from the document
              document.body.removeChild(link);
            }

            downloadPDF(res.blobName, res.contentType, res.fileData);
            setDownloading(false);
          },
          (err: any) => {
            dispatch(
              dispatchError({
                message: err.message,
                statusText: err.response.statusText,
                title: err.response.data.title,
                status: err.response.status,
                detail: err.response.data.detail,
                data: err.response.data,
              })
            );
            setDownloading(false);
          }
        );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth={false}
        sx={{
          my: theme.spacing(4),
        }}
      >
        <CssBaseline />
        <Stack
          alignItems={"center"}
          alignContent={"center"}
          gap={2}
          flexDirection={"row"}
          sx={{
            border: 1,
            borderColor: (mainTheme) => mainTheme.palette.grey[300],
            borderTop: 4,
            borderRadius: "10px",
            borderTopColor: (mainTheme) => mainTheme.palette.primary.main,
            justifyContent: { xs: "center", md: "space-between" },
            flexDirection: { xs: "column", md: "row" },
            maxWidth: { xs: "100%", lg: "50%" },
            py: 0.5,
            px: 2,
          }}
        >
          <Grid item xs={6} md={3}>
            <>
              <p style={{ margin: "0 auto .5rem" }}>
                <strong>Buyer:</strong>
              </p>
              <p style={{ margin: "0 auto" }}>
                {state?.createChangeOrderForm?.buyerType === "spec" && "Spec"}

                {!isNil(state?.changeOrder?.customerBuyer) &&
                  isString(state?.changeOrder?.customerBuyer) &&
                  state?.changeOrder?.customerBuyer}

                {state?.createChangeOrderForm?.buyerType !== "spec" &&
                  !isNil(state?.createChangeOrderForm?.customerBuyer) &&
                  !isString(state?.createChangeOrderForm?.customerBuyer) &&
                  `${state?.createChangeOrderForm?.customerBuyer?.buyerFirstName} ${state?.createChangeOrderForm?.customerBuyer?.buyerLastName}`}
              </p>
            </>
          </Grid>
          <Grid item xs={6} md={3}>
            <p style={{ margin: "0 auto .5rem" }}>
              <strong>Community:</strong>
            </p>
            <p style={{ margin: "0 auto" }}>
              {state.changeOrder.community?.name || null}
            </p>
          </Grid>
          <Grid item xs={6} md={3}>
            <p style={{ margin: "0 auto .5rem" }}>
              <strong>Plan / Elevation:</strong>
            </p>
            <p style={{ margin: "0 auto" }}>
              {state.changeOrder.plan?.planNumber || null} /{" "}
              {state.changeOrder.elevation?.planElevationNumber || null}
            </p>
          </Grid>

          <Box justifyContent={"center"} maxWidth={500}>
            {state.changeOrder.buyerType !== "spec" && (
              <PrepareChangeOrder
                modalTitle={"Edit Change Order"}
                mode="create"
                buttonTitle={"Edit Change Order"}
                createButtonTitle={"Edit"}
                intentionId={intentionId as string}
              />
            )}
          </Box>
          <Box maxWidth={500}>
            <LoadingButton
              size="medium"
              fullWidth
              loading={downloading}
              variant="outlined"
              onClick={handleDownloadItems}
            >
              Print
            </LoadingButton>
          </Box>
        </Stack>

        <Summary />

        <Box my={(theme) => theme.spacing(2)}>
          <TextField
            multiline
            placeholder="Notes"
            fullWidth
            onChange={(e) =>
              dispatch({
                type: "CreateChangeOrderForm",
                payload: {
                  notes: e.target.value,
                },
              })
            }
            rows={6}
            sx={{
              mt: (theme) => theme.spacing(2),
            }}
          />
        </Box>
        <Stack
          sx={{
            display: "flex",
            flex: 1,
            width: "100%",
            flexDirection: { xs: "column", sm: "row" },

            mt: (theme) => theme.spacing(2),
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() => {
              navigate(-1);
            }}
            variant="outlined"
          >
            Back
          </Button>
          <Stack
            gap={2}
            display={"flex"}
            justifyContent={"flex-end"}
            flex={1}
            width={"100%"}
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              mt: (theme) => theme.spacing(2),
            }}
          >
            {/* <Box>
              <ConfirmChangeOrder />
            </Box> */}

            {state.createChangeOrderForm.buyerType !== "spec" ? (
              <Box maxWidth={500}>
                <PrepareChangeOrder
                  modalTitle={"Edit Change Order"}
                  mode="create"
                  buttonTitle={"Edit Change Order"}
                  createButtonTitle={"Edit"}
                  intentionId={intentionId as string}
                />
              </Box>
            ) : (
              <Button
                onClick={() => Submission()}
                disabled={
                  state.changeOrder.pendingItems.filter(
                    (obj: changeOrderOptions) => !isEmpty(obj.description)
                  ) < 1
                }
                variant="contained"
              >
                Submit
              </Button>
            )}
          </Stack>
        </Stack>
      </Container>
    </ThemeProvider>
  );
}
