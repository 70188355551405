import React, { useState, ReactNode, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UIContext, UIState } from "../../providers/UIProvider";

type AdminRouteProps = {
  children: ReactNode;
};

const AdminRoute: React.FC<AdminRouteProps> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checkedAdminStatus, setCheckedAdminStatus] = useState<boolean>(true);
  const location = useLocation();
  const [state] = useContext<UIState | any>(UIContext);

  if (!checkedAdminStatus) {
    // Optionally render a loading spinner or some placeholder
    return <div>Loading...</div>;
  }

  return state.isAdmin ? (
    <>{children}</>
  ) : (
    <Navigate to="/" replace state={{ from: location }} />
  );
};

export default AdminRoute;
