import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
  const { instance } = useMsal();
  const handleLogout = (logoutType: "popup" | "redirect") => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };
  return (
    <Button
      fullWidth
      variant="outlined"
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main, // Adjust this to the exact blue you're using.
        color: "white",
        textTransform: "none", // Optional: to avoid all uppercase letters
        "&:hover": {
          backgroundColor: "#0056b3", // Darkened blue for hover.
          // If you want to add a transition effect:
          transition: "background-color 0.3s",
        },
      }}
      onClick={() => handleLogout("redirect")}
    >
      Sign Out
    </Button>
  );
};
