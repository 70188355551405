import {
  //useState,
  useContext,
  useEffect,
} from "react";
import { dispatchError } from "../../common/fx";
import ReusableDialog from "../../components/Dialog";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { UIContext, UIState } from "../../providers/UIProvider";
//import { useNavigate } from "react-router-dom";
import {
  // startNewIntention,
  // newIntention,
  communityDropdownApi,
  // getAllRoles,
  AddUserAdmin,
  getAllUsers,
  SetUserAccess,
} from "../../apiCalls";
import Step1 from "./Step1";
import Step2 from "./Step2";
import useAccessControl from "../../hooks/useAccessControl";

const AddUser = ({
  form,
  setForm,
  searchOptions,
  open,
  setOpen,
  step,
  pageSize,
  setStep,
  userList,
  setUserList,
}: any) => {
  //const navigate = useNavigate();
  const communityDropdownApiAccess = useAccessControl(
    "Community",
    "GetActiveCommunities"
  );

  const [state, dispatch] = useContext<UIState | any>(UIContext);
  // const [roleList, setRoleList] = useState<any>([]);
  // const [checked, setChecked] = useState([0]);

  /* const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const [loadingUserName, setLoadingUserName] = useState<any>("");

  const sendToEdit = () => {
    function cleanObject(obj: Record<string, any>): Record<string, any> | null {
      const cleaned: Record<string, any> = {};
      for (const key in obj) {
        if (
          obj[key] === "" ||
          obj[key] === undefined ||
          (typeof obj[key] === "object" &&
            obj[key] !== null &&
            Object.keys(obj[key]).length === 0)
        ) {
          continue; // Skip empty strings, undefined values, and empty objects
        }

        if (typeof obj[key] === "object" && obj[key] !== null) {
          const cleanedSubObject = cleanObject(obj[key]);
          if (cleanedSubObject && Object.keys(cleanedSubObject).length > 0) {
            // Only add non-empty sub-objects
            cleaned[key] = cleanedSubObject;
          }
        } else {
          cleaned[key] = obj[key];
        }
      }
      return Object.keys(cleaned).length > 0 ? cleaned : null;
    }

    const {
      address,
      addressFreeform,
      addressType,
      block,
      buyerType,
      community,
      customerBuyer,
      elevation,
      lot,
      plan,
    } = state.createChangeOrderForm;
    //always required fields:
    const payload: any = {
      community: {
        projectID: community.projectId,
        projectNumber: community.projectNumber,
      },
      buyer: {
        buyerType: buyerType,
        buyerFirstName: customerBuyer.buyerFirstName
          ? customerBuyer.buyerFirstName
          : customerBuyer,
        salesforceAccountID: customerBuyer.salesforceAccountId,
        // salesforceConnectionNumber: customerBuyer.connectionNumber,
        buyerLegalName: customerBuyer.contractBuyerNames,
      },
      address: {
        knownAddress: {
          jobID: address.jobID,
          jobNumber: address.jobNumber,
        },
        lotAndBlock: {
          lot: lot,
          block: block,
        },
        freeformAddress: addressFreeform,
      },
      planId: plan.planId ? plan.planId : address.planId,
      planElevationId: elevation.planElevationID
        ? elevation.planElevationID
        : address.planElevationId,
      planNumberOverride:
        addressType === "knownAddress" &&
        address?.planNumber !== plan?.planNumber,
      planElevationOverride:
        addressType === "knownAddress" &&
        address?.planElevationNumber !== elevation?.planElevationNumber,
    };
    if (buyerType !== "Spec") {
      payload.buyer.buyerLastName = customerBuyer.buyerLastName
        ? customerBuyer.buyerLastName
        : "_";
    } else if (buyerType === "Spec") {
      payload.buyer.buyerLastName = undefined;
      payload.buyer.buyerFirstName = undefined;
    }
    //console.log(payload);
    console.log(cleanObject(payload));
    startNewIntention(
      cleanObject(payload) as newIntention,
      (res: any) => navigate(`/edit?intentionId=${res.data}`),
      (err: any) => {
        dispatch(
          dispatchError({
            message: err.message,
            statusText: err.response.statusText,
            title: err.response.data.title,
            status: err.response.status,
            detail: err.response.data.detail,
            data: err.response.data,
          })
        );
      }
    );
    dispatch({
      type: "ResetChangeOrderPending",
    });

    //navigate(`/edit`);
  };*/

  const fetchCommunities = () => {
    communityDropdownApi(
      (res: any) => {
        dispatch({
          type: "Communities",
          payload: res.data,
        });
      },
      (res: any) =>
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
            severity: "error",
          },
        })
    );
  };

  /*const fetchRoles = () => {
    getAllRoles(
      (res: any) => {
        setRoleList(res.data);
      },
      (res: any) =>
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
            severity: "error",
          },
        })
    );
  };*/

  useEffect(() => {
    communityDropdownApiAccess && fetchCommunities();
    // fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityDropdownApiAccess]);

  const AddUserLogic = () => {
    AddUserAdmin(
      {
        UserObjectId: form?.user?.userObjectId,
        UserPrincipalName: form?.user?.userPrincipalName,
        UserName: form?.user?.fullName,
      },
      (res: any) => {
        setStep(1);
        getAllUsers(
          { search: searchOptions?.search, PageSize: pageSize, PageNumber: 1 },
          (res: any) => {
            const formatted = res.data.usersInfo.map((obj: any) => ({
              ...obj,
              id: obj.userObjectId,
            }));
            setUserList(formatted);
            // setLoadingUserName(false);
          },
          (err: any) =>
            dispatch(
              dispatchError({
                message: err.message,
                statusText: err.response.statusText,
                title: err.response.data.title,
                status: err.response.status,
                detail: err.response.data.detail,
                data: err.response.data,
              })
            )
        );
      },
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  };

  const ModifyUser = () => {
    console.log(form);
    SetUserAccess(
      {
        userObjectId: form?.user?.userObjectId,
        roles: form?.role?.map((obj: { name: string }) => obj?.name),
        communities: form.communities,
      },
      (res: any) => {
        setOpen(false);
        getAllUsers(
          { search: searchOptions?.search, PageSize: pageSize, PageNumber: 1 },
          (res: any) => {
            console.log(res);

            const formatted = res.data.usersInfo.map((obj: any) => ({
              ...obj,
              id: obj.userObjectId,
            }));
            setUserList(formatted);
            // setLoadingUserName(false);
          },
          (err: any) =>
            dispatch(
              dispatchError({
                message: err.message,
                statusText: err.response.statusText,
                title: err.response.data.title,
                status: err.response.status,
                detail:
                  err.response.data.detail ??
                  err.response.data.errors.OperationCanceledException[0],
                data: err.response.data,
              })
            )
        );
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `${form?.user?.fullName}'s access permission has been successfully updated`,
            severity: "success",
          },
        });
      },
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  };
  return (
    <ReusableDialog
      setIsOpen={(value) => setOpen(value)}
      isOpen={open}
      onChange={() => {
        setStep(0);
        setForm({
          communities: [],
          role: [],
          user: { fullName: "", userPrincipalName: "", userObjectId: "" },
        });
      }}
      maxWidth={"lg"}
      content={
        <>
          <DialogContent>
            {step === 0 && <Step1 form={form} setForm={setForm} />}
            {step === 1 && (
              <Step2
                userList={userList}
                setUserList={setUserList}
                form={form}
                setForm={setForm}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
                dispatch({
                  type: "ResetCreateChangeOrderForm",
                });
              }}
              variant="outlined"
              color="primary"
            >
              Exit
            </Button>

            {form?.user?.userPrincipalName !==
              state.isAdmin.userPrincipalName && (
              <Button
                onClick={() => {
                  if (step === 0) {
                    AddUserLogic();
                  } else ModifyUser();
                }}
                variant="contained"
                color="primary"
              >
                {step === 0 && "Add User"}
                {step === 1 && "Modify User"}
              </Button>
            )}
          </DialogActions>
        </>
      }
      buttonText="Add User"
      title={step === 0 ? "Add User" : "Modify User"}
    />
  );
};

export default AddUser;
