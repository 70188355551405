import { useState, useContext, useEffect } from "react";
import { dispatchError } from "../../common/fx";
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Popper,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { CommunityTypes, UIContext, UIState } from "../../providers/UIProvider";
import {
  communityDropdownApi,
  getAllRoles,
  UpdateUserStatus,
} from "../../apiCalls";
import { isNil } from "lodash";
import useAccessControl from "../../hooks/useAccessControl";

interface RoleTypes {
  added: string;
  description: string;
  id: string;
  name: string;
  roleApis: string[];
  scope: string;
  teamName: string;
}
type Props = {
  setForm: (form: {
    communities: CommunityTypes[];
    role: any;
    user: any;
  }) => void;
  form: {
    communities: CommunityTypes[];
    role: any;
    user: any;
  };
  userList: any;
  setUserList: any;
};

function CustomPopper(props: any) {
  return <Popper {...props} placement="top" />;
}

const CommunityDropdown = ({ form, handleSelectAll, setForm, state }: any) => {
  const autocompleteOptions = [
    { name: "Select All", specialOption: true },
    ...state.communities,
  ];

  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      multiple
      disableClearable
      fullWidth
      disabled={!form.user.status}
      limitTags={1}
      inputValue={inputValue}
      PopperComponent={CustomPopper}
      disableCloseOnSelect
      size="small"
      clearOnBlur={false}
      value={form.communities ?? []}
      onChange={(event, value, reason, details) => {
        //@ts-ignore
        if (event.code === "Backspace") {
          return null;
        }

        if (details?.option?.specialOption) {
          handleSelectAll();
          return;
        }

        const isOptionPresent = form?.communities
          ? form.communities.some(
              (c: { projectNumber: String }) =>
                c.projectNumber === details?.option.projectNumber
            )
          : false;
        let updatedCommunities = [];
        if (isOptionPresent) {
          updatedCommunities = form.communities.filter(
            (c: { projectNumber: String }) =>
              c.projectNumber !== details?.option.projectNumber
          );
        } else {
          updatedCommunities = [
            ...(form?.communities ?? []),
            details?.option as CommunityTypes,
          ];
        }

        setForm({
          ...form,
          communities: updatedCommunities,
        });
      }}
      options={autocompleteOptions}
      getOptionLabel={(option) =>
        `${option.name} ${
          option?.projectNumber ? `(${option.projectNumber})` : ""
        }`
      }
      renderOption={(props, option, { selected }) => (
        <>
          <li {...props}>
            <Checkbox
              style={{ marginRight: 8 }}
              checked={
                form.communities
                  ? option?.specialOption
                    ? form.communities.length === state?.communities?.length
                    : form.communities.some(
                        (c: { projectNumber: String }) =>
                          c.projectNumber === option.projectNumber
                      )
                  : false
              }
            />
            <ListItemText
              primary={`${option.name} ${
                option?.projectNumber ? `(${option.projectNumber})` : ""
              }`}
            />
          </li>
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Communities"
          onChange={(e) => setInputValue(e.target.value)}
          fullWidth
        />
      )}
      renderTags={(value, getTagProps) => {
        const numTags = value.length;
        const limitTags = 1;

        return (
          <>
            {value.slice(0, limitTags).map((option, index) => (
              <Typography variant="body1" marginLeft={1} marginRight={2}>
                {option.name}{" "}
              </Typography>
            ))}
            {numTags > limitTags && ` +${numTags - limitTags}`}
          </>
        );
      }}
    />
  );
};

const Step2 = ({ form, setForm, userList, setUserList }: Props) => {
  const communityDropdownApiAccess = useAccessControl(
    "Community",
    "GetActiveCommunities"
  );
  const getAllRolesAccess = useAccessControl("UserAdmin", "GetRoles");
  const [loadingBuyerName, setLoadingBuyerNames] = useState(false);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [roleList, setRoleList] = useState<any>([]);
  const [checked, setChecked] = useState([0]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSelectAll = () => {
    if (form?.communities?.length === state?.communities?.length) {
      // If all are selected, deselect all
      setForm({
        ...form,
        communities: [],
      });
    } else {
      // Otherwise, select all
      setForm({
        ...form,
        communities: state.communities.slice(),
      });
    }
  };

  const fetchCommunities = () => {
    communityDropdownApi(
      (res: any) => {
        dispatch({
          type: "Communities",
          payload: res.data,
        });
      },
      (res: any) =>
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
            severity: "error",
          },
        })
    );
  };

  const fetchRoles = () => {
    getAllRolesAccess &&
      getAllRoles(
        (res: any) => {
          setRoleList(res.data);
        },
        (res: any) =>
          dispatch({
            type: "Snackbar",
            payload: {
              show: true,
              message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
              severity: "error",
            },
          })
      );
  };

  useEffect(() => {
    communityDropdownApiAccess && fetchCommunities();
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityDropdownApiAccess]);

  return (
    <Stack
      gap={2}
      width={"100%"}
      justifyContent={"space-evenly"}
      flexDirection={"row"}
    >
      <Stack width={"50%"} flexDirection={"column"}>
        <Box marginY={(theme) => theme.spacing(2)}>
          {
            <Autocomplete
              fullWidth
              disabled
              multiple={false}
              disableCloseOnSelect
              loading={loadingBuyerName}
              size="small"
              defaultValue={""}
              value={form?.user || null}
              onChange={(e, value) => {
                setForm({
                  ...form,
                  user: value,
                });
              }}
              onInputChange={(e, value) => {
                if (!isNil(value) && value.length >= 3) {
                  dispatch({
                    type: "CreateChangeOrderForm",
                    payload: {
                      customerBuyer: value,
                      buyerType: "buyer",
                    },
                  });
                  if (e && e?.type !== "click") {
                    setLoadingBuyerNames(true);
                  }
                }
              }}
              options={userList}
              getOptionLabel={(option) => {
                return `${option.fullName}`;
              }}
              filterOptions={(options: any[], state: any) => {
                let newOptions: any = [];
                // Escape special regex characters in the input value and convert to lower case
                const inputValue = state.inputValue
                  .toLowerCase()
                  .replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
                const regex = new RegExp(`\\b${inputValue}`, "i"); // 'i' for case insensitive

                options.forEach((element) => {
                  let name = element?.fullName?.toLowerCase();
                  // Check if any word starts with the input value
                  if (regex.test(name)) {
                    newOptions.push(element);
                  }
                });
                return newOptions;
              }}
              renderOption={(props, item) => (
                <li {...props} key={item.fullName}>
                  <ListItemText>{item.fullName}</ListItemText>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="User"
                  fullWidth
                />
              )}
            />
          }
        </Box>
        <Box marginY={(theme) => theme.spacing(2)}>
          <FormGroup
            sx={{
              width: "100%",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={form?.user?.status}
                  onChange={(e, checked) => {
                    console.log(userList);
                    const updatedList = userList.map((obj: any) =>
                      obj.userObjectId === form?.user?.userObjectId
                        ? { ...obj, active: checked }
                        : obj
                    );
                    setForm({
                      ...form,
                      user: {
                        ...form.user,
                        status: checked,
                      },
                    });
                    console.log(form);
                    UpdateUserStatus(
                      {
                        userObjectId: form?.user?.userObjectId,
                        userId: form?.user?.userPrincipalName,
                        active: checked,
                      },
                      (res: any) => {
                        setUserList(updatedList);
                        dispatch({
                          type: "Snackbar",
                          payload: {
                            show: true,
                            message: `${form?.user?.fullName}'s access permission has been successfully updated`,
                            severity: "success",
                          },
                        });
                      },
                      (err: any) => dispatch(dispatchError(err.response.data))
                    );
                  }}
                  inputProps={{ "aria-label": "Active" }}
                />
              }
              label="Active"
            />
          </FormGroup>
        </Box>

        <Box marginY={(theme) => theme.spacing(2)}>
          <Autocomplete
            multiple
            disableClearable
            fullWidth
            disabled={!form.user.status}
            limitTags={1}
            PopperComponent={CustomPopper}
            disableCloseOnSelect // Keep the menu open on select
            size="small"
            value={form?.role ?? []} // Ensure value is always an array
            onChange={(event, value, reason, details) => {
              //@ts-ignore
              if (event.code === "Backspace") {
                return null;
              }

              // Check if the option is already present in the form.communities
              const isOptionPresent = form?.role
                ? form?.role?.some(
                    (c: RoleTypes) =>
                      c.description === details?.option?.description
                  )
                : false;

              let updatedRoles: RoleTypes[] = [];
              if (isOptionPresent) {
                // Option is present, so we remove it
                updatedRoles = form.role.filter(
                  (c: RoleTypes) =>
                    c.description !== details?.option?.description
                );
              } else {
                // Option is not present, so we add it
                updatedRoles = [
                  ...(form.role ?? []),
                  details?.option as RoleTypes,
                ];
              }

              setForm({
                ...form,
                role: updatedRoles,
              });
            }}
            options={roleList} // Assuming this is an array of `CommunityTypes`
            getOptionLabel={(option) => {
              return option.description;
            }}
            renderOption={(props, option, { selected }) => (
              <>
                {" "}
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={
                      form.role
                        ? form.role.some(
                            (c: RoleTypes) => c.name === option.name
                          )
                        : false
                    }
                  />
                  <ListItemText primary={option.name} />
                </li>
              </>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Roles"
                fullWidth
              />
            )}
            renderTags={(value, getTagProps) => {
              const numTags = value.length;
              const limitTags = 1;

              return (
                <>
                  {value.slice(0, limitTags).map((option, index) => (
                    <Typography variant="body1" marginLeft={1} marginRight={2}>
                      {option.name}{" "}
                    </Typography>
                  ))}
                  {numTags > limitTags && ` +${numTags - limitTags}`}
                </>
              );
            }}
          />
        </Box>

        <Box marginY={(theme) => theme.spacing(2)}>
          <CommunityDropdown
            state={state}
            setForm={setForm}
            form={form}
            handleSelectAll={handleSelectAll}
          />
        </Box>
      </Stack>
      <div
        style={{
          marginTop: "10px",
        }}
      >
        <Divider orientation="vertical" />
      </div>
      <Stack width={"100%"} flexDirection={"column"}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          marginY={(theme) => theme.spacing(2)}
        >
          <List
            subheader={<ListSubheader>Selected Roles</ListSubheader>}
            sx={{
              width: "100%",
              maxHeight: 500,
              overflow: "scroll",
              bgcolor: "background.paper",
            }}
          >
            {form?.role &&
              form?.role
                .sort(
                  (
                    a: {
                      description: string;
                    },
                    b: {
                      description: string;
                    }
                  ) => a.description.localeCompare(b.description)
                )
                .map((obj: CommunityTypes, index: number) => {
                  return (
                    <ListItem key={obj.projectId} disablePadding>
                      <ListItemButton
                        role={undefined}
                        onClick={handleToggle(index)}
                        dense
                      >
                        <ListItemText id={obj.name} primary={`${obj.name}`} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
          </List>
          <Divider variant="inset" orientation="vertical" />

          <List
            subheader={<ListSubheader>Selected Communities</ListSubheader>}
            sx={{
              width: "100%",
              maxHeight: 500,
              overflow: "scroll",
              bgcolor: "background.paper",
            }}
          >
            {form?.communities &&
              form?.communities
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((obj: CommunityTypes, index: number) => {
                  return (
                    <ListItem key={obj.projectId} disablePadding>
                      <ListItemButton
                        role={undefined}
                        onClick={handleToggle(index)}
                        dense
                      >
                        <ListItemText
                          id={obj.projectNumber}
                          primary={`${obj.projectNumber} - ${obj.name}`}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
          </List>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Step2;
