import {
  DataGridPro,
  GridColDef,
  //GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { FormikProps } from "formik";
//import { divide } from "lodash";
//import React from "react";
import { numberWithCommas } from "../../../utils/formatMoney";
//import { LinearProgress } from "@mui/material";

type Props = {
  formik: FormikProps<{
    adjustmentReason: string;
    adjustAmount: number;
    adjustBy: "percentage" | "dollar";
    selectedHomes: any;
  }>;
};
const Step2 = ({ formik }: Props) => {
  const columns: GridColDef[] = [
    {
      field: "address",
      headerName: "Address",
      width: 200,
      flex: 1,
    },
    {
      field: "planNumber",
      headerName: "Plan",
      flex: 1,
    },
    {
      field: "planElevationNumber",
      headerName: "Elevation",
      flex: 1,
    },
    {
      field: "stage",
      headerName: "Stage",
      flex: 1,
    },
    {
      field: "newContractPrice",
      headerName: "Current Price",
      width: 180,

      valueFormatter: (params) => `$${numberWithCommas(params.value)}`,

      flex: 1,
    },
    {
      field: "adjustedSalesPrice",
      headerName: "Adjusted Price",
      width: 180,
      flex: 1,
      valueGetter: (params: any) => {
        return `${
          formik.values.adjustAmount &&
          (
            Number(params.row.newContractPrice) +
            Number(formik.values.adjustAmount)
          ).toFixed(2)
        }`;
      },
      valueFormatter: (params) => `$${numberWithCommas(params.value)}`,
    },
  ];
  return (
    <div>
      {formik.values.selectedHomes && (
        <DataGridPro
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          columns={columns}
          rows={formik.values.selectedHomes}
          rowCount={formik.values.selectedHomes.length}
          pagination
        />
      )}
    </div>
  );
};

export default Step2;
