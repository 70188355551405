import axios from "axios";
import { functionAppScope } from "../authConfig";
import { msalInstance } from "../index";
import { InteractionRequiredAuthError, AccountInfo } from "@azure/msal-browser";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
let idToken = "";

function fetchAccessToken() {
  const account = msalInstance.getAllAccounts();
  return new Promise((resolve) => {
    msalInstance
      .acquireTokenSilent({ ...functionAppScope, account: account[0] })
      .then((response) => {
        idToken = response.idToken;
        resolve(response.accessToken);
      })
      .catch((e) => {
        if (e instanceof InteractionRequiredAuthError) {
          msalInstance.acquireTokenRedirect({
            ...functionAppScope,
            account: msalInstance.getActiveAccount() as AccountInfo,
          });
        } else {
          // Handle other errors here
          console.error(e);
        }
      });
  });
}

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = await fetchAccessToken();
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${idToken}`;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect user or handle 401 Unauthorized error
      msalInstance.loginRedirect();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
