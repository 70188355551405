import { useState, SyntheticEvent, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  TextField,
} from "@mui/material";
import {
  GridRowModes,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import {
  OptionTypes,
  UIContext,
  UIState,
} from "../../providers/UIProvider";
import { numberWithCommas } from "../../utils/formatMoney";

export default function DropdownOptionsCell(props: any) {
  const { id, value, field, colDef, setRows, Rows, setRowModesModel } = props;
  const [state] = useContext<UIState | any>(UIContext);
  const [isBeingEdited, setIsBeingEdited] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<any>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [isShifted, setIsShifted] = useState<boolean>(false);
  const [dropdownOptions, setDropdownOptions] = useState<any[]>([]);
  const apiRefContext = useGridApiContext();

  useEffect(() => {
    if(field === "option" || props.row.option === "" || props.row.option === null)
      setDropdownOptions(colDef.valueOptions);
    else 
      setDropdownOptions(colDef.valueOptions
        .filter((obj: { category: string }) =>
          obj.category === props.row.category
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [colDef.valueOptions]);
  
  useEffect(() => {
    setInputValue(value || "");
    switch (field) {
      case "option":
        setCurrentValue((value && { category: value }) || null);
        break;
      case "description":
        setCurrentValue((value && { description: value }) || null);
        break;
      case "optionCode":
        setCurrentValue((value && { optionCode: value }) || null);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isBeingEdited])

  const handleEvaluate = (option:any, value:any) => {
    switch (field) {
      case "option":
        //console.log(`evaluating on ${field}: '${value.description}' against ${option.description}`);
        return option.category === value.category;
      case "description":
        //console.log(`evaluating on ${field}: '${value.category}' against ${option.category}`);
        return option?.category === value.category;
      case "optionCode":
        //console.log(`evaluating on ${field}: '${value.optionCode}' against ${option.optionCode}`);
        return option.optionCode === value.optionCode;
      default:
        return false;
    }
  };

  const handleOptionLabel = (option:any) => {
    switch (field) {
      case "option":
        return `${option.category}`;
      case "description":
        return `${option.description} ${parseFloat(option.salesPrice) !== 0 ? `($${numberWithCommas(parseFloat(option.salesPrice))})` : ""}`;
      case "optionCode":
        return `${option.optionCode}`;
      default:
        return `${option.description}`;
    }
  };

  const handleOnOpen = (event:any) => {
    setIsBeingEdited(true);
    setInputValue(value ? value : "");
  };

  const handleOnClose = (event:any) => {
    setIsBeingEdited(false);
  };

  const handleInputChange = (event:any, value:any, reason:any) => {
    if(event !== null && isBeingEdited) {
      setInputValue(value);
    }
  }

  const handleChange = (
    event: SyntheticEvent,
    newValue: OptionTypes | null
  ) => {
    if (newValue === null) {
      const updatedArray = Rows.map((obj: any) => {
        if (obj.id === props.id) {
          if (field === "option") {
            return {
              ...obj,
              highlandCategory: obj.highlandCategory,
              id: props.id,
              isNew: true,
              notes: obj.notes,
              description: null,
              manualPrice: false,
              option: null,
              optionCode: null,
              quantity: "1",
              salesPriceControlID: null,
              unitPrice: 0,
              [props.field]: "",
              sortOrder: obj.sortOrder
            };
          }
          if (field === "optionCode" || field === "description") {
            return {
              ...obj,
              highlandCategory: obj.highlandCategory,
              id: props.id,
              isNew: true,
              notes: obj.notes,
              description: null,
              manualPrice: false,
              option: null,
              optionCode: null,
              quantity: "1",
              salesPriceControlID: null,
              unitPrice: 0,
              [props.field]: "",
              sortOrder: obj.sortOrder
            };
          }
          return {
            ...obj,
            highlandCategory: obj.highlandCategory,
            id: props.id,
            isNew: true,
            notes: obj.notes,
            option: obj.category,
            optionCode: obj.optionCode,
            quantity: "1",
            unitPrice: 0,
            [props.field]: "",
            sortOrder: obj.sortOrder
          };
        }
        return obj;
      });
      setRows({
        type: "ChangeOrderPending",
        payload: updatedArray,
        source: "dropdown handleChange on null"
      });
      if (field === "option") {
        apiRefContext.current.setEditCellValue({
          id,
          field,
          value: null,
        });
        apiRefContext.current.setEditCellValue({
          id,
          field: "optionCode",
          value: null,
        })
        apiRefContext.current.setEditCellValue({
          id,
          field: "description",
          value: null,
        });
      } else if (field === "optionCode") {
        apiRefContext.current.setEditCellValue({
          id,
          field: "option",
          value: null,
        });
        apiRefContext.current.setEditCellValue({
          id,
          field,
          value: null,
        });
        apiRefContext.current.setEditCellValue({
          id,
          field: "description",
          value: null,
        });
      } else if (field === "description") {
        apiRefContext.current.setEditCellValue({
          id,
          field: "option",
          value: null,
        });
        apiRefContext.current.setEditCellValue({
          id,
          field,
          value: null,
        });
        apiRefContext.current.setEditCellValue({
          id,
          field: "optionCode",
          value: null,
        });
      } else {
        apiRefContext.current.setEditCellValue({
          id,
          field,
          value: null,
        });
      }
    } else {
      let possibleOptions = [];
      let newOptionCode: string | null = null;
      let newDescription: string | null = null;
      let newOption: string | null = null;
      let newUnitPrice: number | string = newValue.salesPrice ? newValue.salesPrice : 0;
      const updatedArray = Rows.map((obj: any) => {
        if (obj.id === props.id) {
          let forcedOption: boolean = obj.forcedOption || false;
          let newManualPrice: boolean = obj.manualPrice || false;
          let newSalesPriceControlID: number | null = null;
          newOption = obj.option;
          if (field === "optionCode" || field === "description") {
            const selectedOption = colDef.valueOptions.find((option:OptionTypes) => {
              if (field === "optionCode")
                return option.optionCode === newValue.optionCode;
              return option.description === newValue.description;
            });
            console.log('selectedOption', selectedOption);
            forcedOption = selectedOption.forcedOption;
            newManualPrice = selectedOption.manualPrice;
            if (newOption === "" || newOption === null)
              newOption = selectedOption.category;
          }
          if (field === "description") {
            return {
              ...newValue,
              forcedOption,
              highlandCategory: obj.highlandCategory,
              id: props.id,
              isNew: true,
              notes: obj.notes,
              manualPrice: newManualPrice,
              option: newOption,
              optionCode: newValue.optionCode,
              description: newValue.description,
              quantity: "1",
              salesPriceControlID: newValue.salesPriceControlID,
              unitPrice: newValue.salesPrice ? newValue.salesPrice : 0,
              sortOrder: obj.sortOrder
            };
          } else if (field === "option") {
            possibleOptions = state.availableOptions.filter((option:OptionTypes) => 
              option.highlandCategory === newValue.highlandCategory && option.category === newValue.category);
            if(possibleOptions.length === 1) {
              newOptionCode = possibleOptions[0].optionCode;
              newDescription = possibleOptions[0].description;
              newUnitPrice = possibleOptions[0].salesPrice;
              newManualPrice = possibleOptions[0].manualPrice;
              newSalesPriceControlID = possibleOptions[0].salesPriceControlID;
            }
            return {
              ...newValue,
              forcedOption,
              highlandCategory: obj.highlandCategory,
              id: props.id,
              isNew: true,
              notes: obj.notes,
              manualPrice: newManualPrice,
              option: newValue.category,
              optionCode: newValue.category === "Manual Option" || newValue.category === "Formatting Separator" ?
                "" : newOptionCode,
              quantity: newValue.category === "Formatting Separator" ? 0 : 1,
              salesPriceControlID: newSalesPriceControlID,
              unitPrice: newValue.category === "Manual Option" || newValue.category === "Formatting Separator" ?
                0 : newUnitPrice,
              description: newValue.category === "Manual Option" || newValue.category === "Formatting Separator" ?
                "" : newDescription,
              sortOrder: obj.sortOrder
            };
          } else if (field === "optionCode") {
            return {
              ...newValue,
              forcedOption,
              highlandCategory: obj.highlandCategory,
              id: props.id,
              isNew: true,
              manualPrice: newManualPrice,
              notes: obj.notes,
              option: newOption,
              optionCode: newValue.optionCode,
              quantity: "1",
              unitPrice: newValue.salesPrice ? newValue.salesPrice : 0,
              salesPriceControlID: newValue.salesPriceControlID,
              description: newValue.description,
              sortOrder: obj.sortOrder
            };
          }
        }
        return obj;
      });
      setRows({
        type: "ChangeOrderPending",
        payload: updatedArray,
        source: "dropdown handleChange on selected"
      });
      if (field === "option") {
        apiRefContext.current.setEditCellValue({
          id,
          field,
          value: newValue?.category,
        });
        apiRefContext.current.setEditCellValue({
          id,
          field: "optionCode",
          value: newOptionCode
        });
        apiRefContext.current.setEditCellValue({
          id,
          field: "description",
          value: newDescription
        });
      } else if (field === "optionCode") {
        if(props.row.option === "" || props.row.option === null) {
          apiRefContext.current.setEditCellValue({
            id,
            field: "option",
            value: newOption,
          });
        }
        apiRefContext.current.setEditCellValue({
          id,
          field,
          value: newValue?.optionCode,
        });
        apiRefContext.current.setEditCellValue({
          id,
          field: "description",
          value: newValue?.description,
        });
      } else if (field === "description") {
        if(props.row.option === "" || props.row.option === null) {
          apiRefContext.current.setEditCellValue({
            id,
            field: "option",
            value: newOption,
          });
        }
        apiRefContext.current.setEditCellValue({
          id,
          field: "optionCode",
          value: newValue?.optionCode,
        });
        apiRefContext.current.setEditCellValue({
          id,
          field: "description",
          value: newValue?.description,
        });
      }
    }

    setRowModesModel((oldModel: any) => ({
      ...oldModel,
      [props.id]: { mode: GridRowModes.Edit },
    }));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Autocomplete
        fullWidth
        getOptionLabel={handleOptionLabel}
        id={`${props.id}_${field}`}
        onOpen={handleOnOpen}
        onClose={handleOnClose}
        inputValue={inputValue}
        isOptionEqualToValue={handleEvaluate}
        multiple={false}
        onChange={(e: any, value: any) => {
          handleChange(e, value as unknown as OptionTypes);
        }}
        onInputChange={handleInputChange}
        options={dropdownOptions}
        renderInput={(params) => (
          <TextField
            sx={{
              textTransform: "capitalize",
            }}
            {...params}
            onFocus={() => setIsShifted(false)}
            onKeyDown={(event) => {
              let prevRow:any = null;
              if(event.key === "Shift") {
                setIsShifted(true);
              }
              if(event.key === "Tab") {
                const myTarget:any = event.target;
                const myId = myTarget.id?.split("_")[0];
                let nextTab:string = "";
                switch (field) {
                  case "option":
                    if (isShifted) {
                      const currentRow = Rows.find((item:OptionTypes) => item.id === myId);
                      if (currentRow.sortOrder > 1) {
                        prevRow = Rows.find((item:OptionTypes) =>
                          item.sortOrder === currentRow.sortOrder - 1  &&
                          item.highlandCategory.key === props.row.highlandCategory.key
                        );
                      }
                    } else {
                      nextTab = currentValue.category === "Formatting Separator"
                        ? "description"
                        : "optionCode";
                    }
                    break;
                  case "description":
                    nextTab = isShifted ? "optionCode" : "quantity";
                    break;
                  case "optionCode":
                    nextTab = isShifted ?  "option" : "description";
                    break;
                }
                if (prevRow) {
                  const currentInput = document.getElementById(`${myId}_option`);
                  currentInput?.blur();
                  setTimeout(() => {
                    setRowModesModel((oldModel: any) => {
                      const newModel = {
                        [myId]: { mode: GridRowModes.View },
                        [prevRow.id]: { mode: GridRowModes.Edit }
                      };
                      return newModel;
                    });
                    setTimeout(() => {
                      const prevInput = document.getElementById(`${prevRow.id}_option`);
                      prevInput?.focus();  
                    }, (200));
                  }, (200));
                } else if (nextTab) {
                  const nextInput = document.getElementById(`${myId}_${nextTab}`);
                  nextInput?.focus();
                }
              }
            }}
            onKeyUp={(event) => {
              if (event.key === "Shift") {
                setIsShifted(false);
              }
            }}
            variant="outlined"
            label={field}
            fullWidth
          />
        )}
        size="small"
        sx={{
          "& .MuiInputBase-input": {
            height: "1rem!important",
          },
        }}
        value={currentValue || null}
      />
    </Box>
  );
}