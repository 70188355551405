import { errorResponseObject } from "../common/interfaces";

// Builds the Error object for Snackbar:
export const dispatchError = (err: errorResponseObject) => {
  console.log("add user err", err);
  let message = "";
  if (err.status) {
    message += err.status.toString() + " - ";
  }
  if (err.title) {
    message += err.title + " - ";
  }

  console.log(err);
  if (err.detail) {
    if (typeof err.detail === "object") {
      message += JSON.stringify(err.detail);
    } else {
      message += err.detail;
    }
  } else if (!err.detail && err.errors) {
    const errorKeys = Object.keys(err.errors);
    if (errorKeys.length > 0) {
      const firstKey = errorKeys[0];
      const firstErrorMessage = err.errors[firstKey][0];
      message = message += firstErrorMessage;
    } else message = message += "Unknown Error";
  } else message = message += "Unknown Error";

  return {
    type: "Snackbar",
    payload: {
      show: true,
      message,
      severity: "error",
    },
  };
};

export const handleUnchangedNavigation = (
  pathTo: any,
  currentCOstatus: string,
  navigate: any,
  dispatch: any
) => {
  if (currentCOstatus === "editing") {
    const backConfirmation = window.confirm(
      "The Change Order have unsaved changes. To discard the unsaved changes, click OK/Leave. To save your changes, click Cancel to continue editing your work."
    );
    if (backConfirmation) {
      navigate(pathTo);
      dispatch({
        type: "ResetChangeOrder",
      });
      dispatch({
        type: "ResetCreateChangeOrderForm",
      });
    }
  } else navigate(pathTo);
};

export const isElementInViewPort = (element: any) => {
  if (!element) return false;
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) - 100) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
