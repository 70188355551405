import React from "react";
import { UIContext } from "../providers/UIProvider";

interface AdminAccess {
  category: string;
  actions: string[];
}

const useAccessControl = (category: string, action: string): boolean => {
  const [state] = React.useContext<any>(UIContext);

  // Replace the state?.isAdmin?.access with isAdmin for demonstration
  const hasAccess =
    state?.isAdmin?.access
      ?.find((obj: AdminAccess) => obj.category === category)
      ?.actions.some((str: string) => str === action) || false;

  return hasAccess;
};

export default useAccessControl;