import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  LinearProgress,
  ListItemText,
  Stack,
  Switch,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Circle,
  SearchOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridFilterModel,
} from "@mui/x-data-grid-pro";
import {
  CommunityTypes,
  RoleType,
  UIContext,
  UIState,
} from "../../providers/UIProvider";
import { getAllRoles, getAllUsers, getAllUsersPost } from "../../apiCalls";
import { dispatchError } from "../../common/fx";
import AddUser from "../../modals/AddUser/AddUser";
import useLocalStorage from "../../hooks/useLocalStorage";
import { filterOptions } from "../../utils/filterOptions";
import useAccessControl from "../../hooks/useAccessControl";

const UserList = () => {
  const [userList, setUserList] = React.useState<any>([]);
  const [loadingUserName, setLoadingUserName] = React.useState<boolean>(false);
  const [searchOptions, setSearchOptions] = useState<{
    search: any;
  }>({
    search: "",
  });
  const [open, setOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [activeOnly, setActiveOnly] = React.useState<boolean | null>(true);
  const [statuses, setStatuses] = React.useState<any[]>(["Active"]);

  const [pageSize, setPageSize] = React.useState(100);
  const [totalRows, setTotalRows] = useState(0);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 0,
  });

  // Update the pagination model when the TablePagination changes
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setPaginationModel((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));

    setPage(0); // Reset to the first page
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: parseInt(event.target.value, 10),
      page: 0,
    }));
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = React.useContext<UIState | any>(UIContext);
  const [filt, setFilt] = React.useState({
    items: [],
    quickFilterValues: [""],
  });
  const [step, setStep] = useState(0);
  const [communities, setCommunities] = useState<any[]>([]);
  const [roles, setRoles] = useState<any[]>([]);
  const [rolesList, setRolesList] = useState<RoleType[]>([]);

  const getAllRolesAccess = useAccessControl("UserAdmin", "GetRoles");

  const [form, setForm] = useState<{
    communities: CommunityTypes[];
    role: any;
    user: {
      fullName: string;
      userPrincipalName: string;
      userObjectId: string;
      status: boolean;
    };
  }>({
    communities: [],
    role: "",
    user: {
      fullName: "",
      userPrincipalName: "",
      userObjectId: "",
      status: true,
    },
  });

  const jobColumns: GridColDef[] = [
    {
      field: "userPrincipalName",
      headerName: "User Name",
      flex: 1,
      maxWidth: 400,
      renderCell: (params) => (
        <Typography
          sx={{
            cursor: "pointer",
            color: "#0000FF",
            textDecoration: "underline",
          }}
          onClick={() => {
            setStep(1);
            setForm({
              communities: params.row.communities,
              role: params.row.grantedRoles,
              user: {
                fullName: params.row.name,
                userPrincipalName: params.row.userPrincipalName,
                userObjectId: params.row.userObjectId,
                status: params.row.active,
              },
            });
            setOpen(true);
          }}
        >
          {params.row.userPrincipalName}
        </Typography>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      maxWidth: 250,
    },
    {
      field: "active",
      headerName: "Active",
      flex: 1,
      maxWidth: 250,
      renderCell: (params) =>
        params.row.active ? (
          <Circle sx={{ color: "#1976d2" }} />
        ) : (
          <Circle sx={{ color: "grey" }} />
        ),
    },
    {
      field: "grantedRoles",
      headerName: "Role Description",
      flex: 1,
      renderCell: (params: any) => (
        <>
          {params?.value?.slice(0, 3).map((obj: any, cIx: number) => (
            <Chip key={cIx} label={obj.description} />
          ))}
        </>
      ),
    },

    {
      field: "communities",
      headerName: "Projects",
      flex: 1,
      renderCell: (params: any) => (
        <>
          {params?.value?.slice(0, 3).map((obj: any, cIx: number) => (
            <Chip key={cIx} label={obj.name} />
          ))}
          {params?.value?.length > 3 && (
            <Chip label={`+${params.value.length - 3}`} />
          )}
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 50,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      hideable: true,
      pinnable: false,
      editable: false,

      renderCell: (params: GridRenderCellParams) => (
        <>
          <Tooltip title={`View User ${params.row.userPrincipalName}`}>
            <IconButton
              color="primary"
              sx={{ p: "10px" }}
              aria-label={`View User ${params.row.userPrincipalName}`}
              onClick={() => {
                setStep(1);
                setForm({
                  communities: params.row.communities,
                  role: params.row.grantedRoles,
                  user: {
                    fullName: params.row.name,
                    userPrincipalName: params.row.userPrincipalName,
                    userObjectId: params.row.userObjectId,
                    status: params.row.active,
                  },
                });
                setOpen(true);
              }}
            >
              <VisibilityOutlined />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  React.useEffect(() => {
    setLoadingUserName(true);

    const params = {
      search: searchOptions?.search,
      pageSize: paginationModel.pageSize,
      pageNumber: paginationModel?.page + 1, // API might expect 1-indexed pages
      roles: roles.map((obj) => obj.name),
      communities: communities,
      activeOnly: activeOnly,
    };

    getAllUsersPost(
      params,
      (res: any) => {
        const formatted = res.data.usersInfo.map((obj: any) => ({
          ...obj,
          id: obj.userObjectId,
        }));
        setTotalRows(res.data.totalUsersCount); // Set the total number of rows from the response
        setUserList(formatted);
        setLoadingUserName(false);
      },
      (err: any) =>
        dispatch(
          dispatchError({
            message: err.message,
            statusText: err.response.statusText,
            title: err.response.data.title,
            status: err.response.status,
            detail: err.response.data.detail,
            data: err.response.data,
          })
        )
    );

    dispatch({
      type: "Navbar",
      payload: { title: `Change Order` },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    paginationModel,
    searchOptions,
    roles,
    communities,
    activeOnly,
  ]);

  React.useEffect(() => {
    getAllRolesAccess &&
      getAllRoles(
        (response: any) => {
          setRolesList(response.data);
        },
        (err: any) => {
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail:
                err.response.data.detail ??
                err.response.data.errors.OperationCanceledException[0],
              data: err.response.data,
            })
          );
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllRolesAccess]);

  return (
    <Stack
      sx={{ minHeight: "40rem", width: "100%", position: "relative" }}
      gap={2}
      p={2}
      flexDirection={"column"}
    >
      <Stack
        mt={2}
        gap={2}
        sx={{
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { xs: "center", md: "space-between" },
        }}
      >
        <Box
          sx={{
            maxWidth: {
              xs: "100%",
              md: "80rem",
            },
          }}
          width={"100%"}
          gap={2}
          display={"flex"}
          alignItems={"center"}
        >
          <TextField
            fullWidth
            sx={{
              maxWidth: "20rem",
            }}
            size="small"
            label="Search"
            variant="outlined"
            onChange={(e: any) =>
              setSearchOptions({
                ...searchOptions,
                search: e.target.value,
              })
            }
            value={searchOptions.search}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined color="info" />
                </InputAdornment>
              ),
            }}
          />
          <Autocomplete
            fullWidth
            multiple
            sx={{
              maxWidth: "20rem",
            }}
            disableCloseOnSelect
            size="small"
            onChange={async (events, value) => {
              setPage(0);
              setCommunities(value);
            }}
            options={state.communities as CommunityTypes[]}
            getOptionLabel={(option) =>
              `${option.name} (${option.projectNumber})`
            }
            value={communities}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Community"
                fullWidth
              />
            )}
            filterOptions={filterOptions}
            renderOption={(props, item) => (
              <li {...props} key={item.projectId}>
                <ListItemText>{`${item.name} (${item.projectNumber})`}</ListItemText>
              </li>
            )}
          />
          <Autocomplete
            fullWidth
            multiple
            sx={{
              maxWidth: "20rem",
            }}
            disableCloseOnSelect
            size="small"
            onChange={async (events, value) => {
              setPage(0);
              setRoles(value);
            }}
            options={rolesList as RoleType[]}
            getOptionLabel={(option) => `${option.description}`}
            value={roles}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Roles"
                fullWidth
              />
            )}
            filterOptions={filterOptions}
            renderOption={(props, item) => (
              <li {...props} key={item.id}>
                <ListItemText>{`${item.description}`}</ListItemText>
              </li>
            )}
          />

          <Autocomplete
            fullWidth
            multiple
            sx={{
              maxWidth: "20rem",
            }}
            disableCloseOnSelect
            size="small"
            onChange={async (events, value, reason) => {
              if (reason === "clear") {
                setStatuses([]);
                setActiveOnly(null);
              } else setStatuses(value);
              if (value.includes("Active") && value.includes("Inactive")) {
                setActiveOnly(null);
              } else if (
                !value.includes("Active") &&
                value.includes("Inactive")
              ) {
                setActiveOnly(false);
              } else if (
                value.includes("Active") &&
                !value.includes("Inactive")
              ) {
                setActiveOnly(true);
              } else setActiveOnly(null);
            }}
            options={["Active", "Inactive"]}
            getOptionLabel={(option) => `${option}`}
            value={statuses}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Status"
                fullWidth
              />
            )}
            filterOptions={filterOptions}
            renderOption={(props, item) => (
              <li {...props} key={item}>
                <ListItemText>{`${item}`}</ListItemText>
              </li>
            )}
          />
        </Box>
        <Box
          maxWidth={"30rem"}
          width={"100%"}
          gap={2}
          sx={{
            display: "flex",
            maxWidth: {
              xs: "100%",
              md: "20rem",
            },
            alignItems: { xs: "space-between", md: "center" },
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        >
          <AddUser
            open={open}
            setOpen={setOpen}
            form={form}
            step={step}
            searchOptions={searchOptions}
            setStep={setStep}
            setForm={setForm}
            userList={userList}
            pageSize={pageSize}
            setUserList={setUserList}
          />
        </Box>
      </Stack>

      <DataGridPro
        paginationMode="server"
        density="compact"
        rowCount={totalRows}
        hideFooter
        loading={loadingUserName}
        columns={jobColumns}
        rows={userList}
        getRowId={(row) => row.userId}
        slots={{
          loadingOverlay: LinearProgress,
        }}
        filterModel={filt as GridFilterModel | undefined}
        onFilterModelChange={(newFilterModel) => {
          console.log(newFilterModel);
          setFilt(newFilterModel as any);
        }}
        sx={{
          marginTop: (theme) => theme.spacing(2),
          height: "100%",
          minHeight: "20rem",
          "& .css-1iledgx-MuiFormControl-root": {
            marginBottom: "0px",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: "visible!important",
          },
        }}
        pagination
      />
      <TablePagination
        component="div"
        count={totalRows} // Total row count comes from the server
        page={page}
        nextIconButtonProps={{
          disabled:
            pageSize > userList.length || loadingUserName ? true : false,
        }}
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangePageSize}
      />
    </Stack>
  );
};

export default UserList;
