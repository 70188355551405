import { useState, useContext, useEffect } from "react";
import { UIState, UIContext } from "../../providers/UIProvider";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
} from "@mui/material";
import { deleteTemplate, getTemplates } from "../../apiCalls";
import useAccessControl from "../../hooks/useAccessControl";

interface ConfirmDeleteTemplateProps {
  templateId: string;
  templateName: string;
  isOpen: boolean;
  onClose: any;
}

const ConfirmDeleteTemplate = (props: ConfirmDeleteTemplateProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const getTemplatesAccess = useAccessControl("OptionTemplate", "GetTemplates");
  useEffect(() => {
    setIsOpen(props.isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const handleClose = () => {
    //setIsOpen(false);
    if (props.onClose) props.onClose();
  };

  const execDeleteTemplate = () => {
    deleteTemplate(
      props.templateId,
      (res: any) => {
        getTemplatesAccess &&
          getTemplates(
            {
              projectId: state.changeOrder.plan.projectID,
              planId: state.changeOrder.plan.planId,
            },
            (res: any) => {
              const templatesData = res.data.optionTemplates
                ? res.data.optionTemplates.map((template: any) => {
                    return {
                      id: template.id,
                      name: template.templateName,
                      createdAt: new Date(template.createdAt),
                      createdBy: template.createdByName,
                    };
                  })
                : [];
              dispatch({
                type: "AvailableTemplates",
                payload: templatesData,
              });
            },
            (res: any) =>
              dispatch({
                type: "Snackbar",
                payload: {
                  show: true,
                  message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
                  severity: "error",
                },
              })
          );
      },
      (res: any) => {
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
            severity: "error",
          },
        });
      }
    );
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      data-testid="dialog-wrapper"
      onClose={() => setIsOpen(false)}
      fullWidth
    >
      <DialogTitle
        data-testid={"dialog-delete-template"}
        sx={(theme: Theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        })}
      >
        Delete Template
      </DialogTitle>
      <DialogContent>
        <>
          <Box marginY={(theme) => theme.spacing(2)}>
            Delete the option template <strong>{props.templateName}</strong>?
          </Box>
        </>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          disabled={props.templateName.trim() === ""}
          onClick={() => {
            execDeleteTemplate();
            handleClose();
          }}
          variant="contained"
          color="primary"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteTemplate;
