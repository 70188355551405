import { useContext } from "react";
import {
  Box,
  Stack,
  Button
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UIContext, UIState } from "../../providers/UIProvider";
import { handleUnchangedNavigation } from "../../common/fx";
import { LoadingButton } from "@mui/lab";
import SaveTemplate from "../../modals/SaveTemplate";

const EditFooter = ({ downloading, handleDownloadItems }: any) => {
  const navigate = useNavigate();
  //const theme = useTheme();

  const [searchParams] = useSearchParams();
  let intentionId = searchParams.get("intentionId");

  const [state, dispatch] = useContext<UIState | any>(UIContext);

  return (
    <Stack
      className="edit-footer"
      justifyContent={"space-between"}
      sx={{
        width: "100%",
        flexDirection: "row",
        marginTop: "0!important",
        alignItems: "center",
      }}
    >
      <Box width={"100%"} display={"flex"} alignItems={"center"}>
        <Button
          onClick={() => handleUnchangedNavigation(`/job-summary?addressid=${intentionId}`, state.changeOrder.status, navigate, dispatch)}
          variant="outlined"
        >
          Back
        </Button>
      </Box>

      <Box
        width={"100%"}
        gap={2}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <SaveTemplate disabled={false} />

        <Stack>
          <LoadingButton
            size="small"
            fullWidth
            loading={downloading}
            variant="outlined"
            onClick={() => handleDownloadItems()}
          >
            Print
          </LoadingButton>
        </Stack>
      </Box>
    </Stack>
  );
};

export default EditFooter;
