import { Skeleton, Stack } from "@mui/material";
import { GridOverlay } from "@mui/x-data-grid-pro";
import _ from "lodash";
const LoadingGridOverlay = ({ pageSize }: { pageSize: number }) => {
  const skeletonStyles = [
    { width: "100%" }, // Job Number
  ];

  const generateSkeletons = (times: number) => {
    return _.times(times, (i) => (
      <Stack direction="row" key={i}>
        {skeletonStyles.map((style, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            height={30}
            style={{ ...style, margin: "6px" }}
          />
        ))}
      </Stack>
    ));
  };

  return (
    <GridOverlay
      sx={{
        zIndex: 1000,
        display: "flex",
        alignItems: "flex-start",
        backgroundColor: "white",
      }}
    >
      <Stack direction={"column"} width={"100%"}>
        {generateSkeletons(pageSize ?? 100)}
      </Stack>
    </GridOverlay>
  );
};

export default LoadingGridOverlay;
