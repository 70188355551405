export const AddForced = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <defs><clipPath id="b"><rect width="24" height="24"/></clipPath></defs><g id="a" clipPath="url(#b)"><rect width="24" height="24" fill="#fff"/><g transform="translate(-1787 -276)"><text transform="translate(1800 294)" fill="#1976d2" fontSize="18" fontFamily="Roboto-BoldItalic, Roboto" fontWeight="700" fontStyle="italic"><tspan x="-9.65" y="0">F</tspan></text><path d="M15,10.714H10.714V15H9.286V10.714H5V9.286H9.286V5h1.429V9.286H15Z" transform="translate(1794 281)" fill="#1976d2"/></g></g>
  </svg>
);

export const DeleteWizard = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <defs><clipPath id="b"><rect width="24" height="24"/></clipPath></defs><g id="a" clipPath="url(#b)"><rect width="24" height="24" fill="#fff"/><g transform="translate(-1857 -276)"><path d="M5.25,7.85v1.3h6.5V7.85ZM8.5,2A6.5,6.5,0,1,0,15,8.5,6.5,6.5,0,0,0,8.5,2Zm0,11.7a5.2,5.2,0,1,1,5.2-5.2A5.207,5.207,0,0,1,8.5,13.7Z" transform="translate(1865 283)" fill="#b71c1c"/><g transform="translate(775.227 -1214.38) rotate(45)"><rect width="4" height="20" transform="translate(1832 280)" fill="#1976d2"/><rect width="2" height="4" transform="translate(1833 281)" fill="#fff"/></g><path d="M2,0l.5,1.5L4,2l-1.5.5L2,4,1.5,2.5,0,2l1.5-.5Z" transform="translate(1864 279)" fill="#1976d2"/><path d="M2,0l.5,1.5L4,2l-1.5.5L2,4,1.5,2.5,0,2l1.5-.5Z" transform="translate(1864 279)" fill="#1976d2"/><path d="M2,0l.5,1.5L4,2l-1.5.5L2,4,1.5,2.5,0,2l1.5-.5Z" transform="translate(1875 282)" fill="#1976d2"/><path d="M2,0l.5,1.5L4,2l-1.5.5L2,4,1.5,2.5,0,2l1.5-.5Z" transform="translate(1875 282)" fill="#1976d2"/></g></g>
  </svg>
);

export const MeasuredWizard = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <defs><clipPath id="b"><rect width="24" height="24"/></clipPath></defs><g id="a" clipPath="url(#b)"><rect width="24" height="24" fill="#fff"/><g transform="translate(-1820 -276)"><g transform="translate(739.227 -1214.38) rotate(45)"><rect width="4" height="20" transform="translate(1832 280)" fill="#1976d2"/><rect width="2" height="4" transform="translate(1833 281)" fill="#fff"/></g><path d="M15.545,6H2.455A1.459,1.459,0,0,0,1,7.455v5.818a1.459,1.459,0,0,0,1.455,1.455H15.545A1.459,1.459,0,0,0,17,13.273V7.455A1.459,1.459,0,0,0,15.545,6Zm0,7.273H2.455V7.455H3.909v2.909H5.364V7.455H6.818v2.909H8.273V7.455H9.727v2.909h1.455V7.455h1.455v2.909h1.455V7.455h1.455Z" transform="translate(1826 283)" fill="#1976d2"/><path d="M2,0l.5,1.5L4,2l-1.5.5L2,4,1.5,2.5,0,2l1.5-.5Z" transform="translate(1828 279)" fill="#1976d2"/><path d="M2,0l.5,1.5L4,2l-1.5.5L2,4,1.5,2.5,0,2l1.5-.5Z" transform="translate(1828 279)" fill="#1976d2"/><path d="M2,0l.5,1.5L4,2l-1.5.5L2,4,1.5,2.5,0,2l1.5-.5Z" transform="translate(1839 282)" fill="#1976d2"/><path d="M2,0l.5,1.5L4,2l-1.5.5L2,4,1.5,2.5,0,2l1.5-.5Z" transform="translate(1839 282)" fill="#1976d2"/></g></g>
  </svg>
);