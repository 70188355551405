import React, { useState } from "react";
import ReusableDialog from "../../components/Dialog";
import { Button, DialogActions, DialogContent } from "@mui/material";
import Step1 from "./Steps/Step1";
import { FormikProps } from "formik";
import Form from "../../components/Form/Form";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import Step2 from "./Steps/Step2";
import { downloadChangeOrderPDF, priceAdjustment } from "../../apiCalls";
import { UIContext, UIState } from "../../providers/UIProvider";
import { dispatchError } from "../../common/fx";
import useAccessControl from "../../hooks/useAccessControl";

type Props = {};

const PriceAdjustment = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = React.useContext<UIState | any>(UIContext);
  const DownloadChangeOrderAccess = useAccessControl(
    "ChangeOrder",
    "DownloadPDF"
  );
  const handleDownloadItems = (intentionId: string) => {
    if (intentionId) {
      DownloadChangeOrderAccess &&
        downloadChangeOrderPDF(
          { changeOrderId: intentionId },
          async (res: {
            fileData: "string";
            contentType: "string";
            documentName: "string";
          }) => {
            function base64ToBlob(
              base64: string,
              contentType: string = ""
            ): Blob {
              // Convert Base64 to a byte array
              const byteCharacters = atob(base64);
              const byteArrays = [];

              for (
                let offset = 0;
                offset < byteCharacters.length;
                offset += 512
              ) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);

                for (let i = 0; i < slice.length; i++) {
                  byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
              }

              // Create a blob from the byte array
              return new Blob(byteArrays, { type: contentType });
            }

            function downloadPDF(
              documentName: string,
              contentType: string,
              fileData: string
            ) {
              // Convert Base64 fileData to Blob
              const blob = base64ToBlob(fileData, contentType);

              // Create a Blob URL
              const blobUrl = window.URL.createObjectURL(blob);

              // Create a link element
              const link = document.createElement("a");

              // Set the download attribute with a filename
              link.download = documentName;

              // Set the href to the blob URL
              link.href = blobUrl;

              // Append the link to the document body
              document.body.appendChild(link);

              // Programmatically click the link to trigger the download
              link.click();

              // Clean-up: remove the link from the document
              document.body.removeChild(link);
            }

            downloadPDF(res.documentName, res.contentType, res.fileData);
          },
          (err: any) => {
            dispatch(
              dispatchError({
                message: err.message,
                statusText: err.response.statusText,
                title: err.response.data.title,
                status: err.response.status,
                detail: err.response.data.detail,
                data: err.response.data,
              })
            );
          }
        );
    }
  };

  // const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState({
    currentStep: 0,
    stepTitle: "Price Adjustment - Job Selection",
  });

  const toggleStep = (formik: any) => {
    switch (step.currentStep) {
      case 0:
        return setStep({
          stepTitle: "Price Adjustment - Selected Jobs",
          currentStep: step.currentStep + 1,
        });

      case 1:
        return (
          setStep({
            stepTitle: "Price Adjustment - Selected Jobs",
            currentStep: step.currentStep - 1,
          }),
          formik.handleSubmit(),
          formik.resetForm(),
          setOpen(false)
        );
    }
  };

  return (
    <>
      <ReusableDialog
        maxWidth={"md"}
        setIsOpen={setOpen}
        isOpen={open}
        content={
          <Form
            onSubmitHandle={(values: any) =>
              priceAdjustment(
                {
                  buyerFirstName: "Spec",
                  buyerLastName: "Spec",
                  buyerLegalName: "Spec",
                  salesForceAccountId: "Spec",
                  salesForceConnectionNumber: "Spec",
                  adjustmentPrice: values.adjustAmount,
                  adjustmentReason: values.adjustmentReason,
                  intentionsList: values.selectedHomes.map(
                    (obj: { id: number }) => obj.id
                  ),
                },
                async (response: any) => {
                  await dispatch({
                    type: "Snackbar",
                    payload: {
                      show: true,
                      message:
                        "Your Price Adjustment Change Order(s) have been submitted and will be downloaded onto your computer",
                      severity: "success",
                    },
                  });
                  await response.data.map((obj: { intentionId: string }) =>
                    handleDownloadItems(obj.intentionId)
                  );
                },
                (err: any) =>
                  dispatch(
                    dispatchError({
                      message: err.message,
                      statusText: err.response.statusText,
                      title: err.response.data.title,
                      status: err.response.status,
                      detail: err.response.data.detail,
                      data: err.response.data,
                    })
                  )
              )
            }
            validationSchema={Yup.object({
              adjustmentReason: Yup.string(),
              adjustAmount: Yup.number(),
              adjustBy: Yup.string(),
            })}
            initialValues={{
              adjustBy: "dollar",
              adjustAmount: null,
            }}
          >
            {(
              formik: FormikProps<{
                adjustmentReason: string;
                adjustAmount: number;
                adjustBy: "percentage" | "dollar";
                selectedHomes: any;
              }>
            ) => {
              const buttonLogic = () => {
                switch (step.currentStep) {
                  case 0:
                    return (
                      isEmpty(formik.values.selectedHomes) ||
                      isEmpty(formik.values.adjustmentReason) ||
                      isEmpty(formik.values.adjustAmount) ||
                      formik.values.adjustAmount > 1000000 ||
                      formik.values.adjustAmount < -1000000
                    );

                  case 1:
                    return (
                      isEmpty(formik.values.adjustmentReason) ||
                      formik.values.adjustAmount === 0
                    );
                }
              };
              return (
                <>
                  <DialogContent>
                    {step.currentStep === 0 && (
                      <Step1
                        formik={formik}
                        setSelectedHomes={(selected: any) =>
                          formik.setFieldValue("selectedHomes", selected)
                        }
                      />
                    )}
                    {step.currentStep === 1 && <Step2 formik={formik} />}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpen(false);
                        setStep({
                          ...step,
                          currentStep: 0,
                        });
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={buttonLogic()}
                      onClick={(e) => toggleStep(formik)}
                      variant="contained"
                      color="primary"
                    >
                      {step.currentStep === 1 ? "Confirm" : "Submit"}
                    </Button>
                  </DialogActions>
                </>
              );
            }}
          </Form>
        }
        buttonText="Price Adjustment"
        title={step.stepTitle}
      />
    </>
  );
};

export default PriceAdjustment;
