import { useState } from "react";

type SetValue<T> = (value: T | ((val: T) => T)) => void;

const useLocalStorage = <T,>(
  key: string,
  initialValue: T
): [T, SetValue<T>] => {
  // Get the initial value from local storage or use the provided initial value
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error("Error retrieving localStorage key", error);
      return initialValue;
    }
  });

  // Function to set the value in both state and local storage
  const setValue: SetValue<T> = (value) => {
    try {
      // Allow value to be a function to replicate the useState API
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error("Error setting localStorage key", error);
    }
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
