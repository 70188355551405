import React, { useState, useEffect, useContext } from "react";
import ReusableDialog from "../../components/Dialog";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { AddComment, Message } from "@mui/icons-material";
import {
  AdminPanelSettings,
  Delete,
  PushPin,
} from "@mui/icons-material";
import { createNote, deleteNote, intentionNotes } from "../../apiCalls";
import moment from "moment";
import { UIContext } from "../../providers/UIProvider";

type Props = {
  setRows?: any;
  rows?: any;
};

type IntentionNoteStructure = {
  intentionNoteId: string;
  intentionId: string;
  noteDate: string;
  note: string;
  adminOnly: boolean;
  pinToTop: boolean;
  showDelete: boolean;
  createdAt: string;
  createdBy: string;
  createdByName: string;
};

const Notes: React.FC<Props> = ({ rows }) => {
  const [state]: any = useContext(UIContext);
  const [notes, setNotes] = useState<IntentionNoteStructure[]>([]);
  const [tempNote, setTempNote] = useState<string>("");
  const [options, setOptions] = useState({ admin: false, pinTop: false });
  const [open, setOpen] = useState<boolean>(false);
  const [loadingComment, setLoadingComment] = useState<boolean>(false);

  useEffect(() => {
    if (rows) {
      intentionNotes(
        { intentionId: rows.id },
        (res: { data: IntentionNoteStructure[] }) => {
          setNotes(sortNotes(res.data));
        },
        (error: Error) => console.error("Error fetching notes:", error)
      );
    }
  }, [rows]);

  const sortNotes = (
    notes: IntentionNoteStructure[]
  ): IntentionNoteStructure[] => {
    return notes.sort((a, b) => {
      if (a.pinToTop !== b.pinToTop) {
        return a.pinToTop ? -1 : 1;
      }
      return moment(b.createdAt).diff(moment(a.createdAt));
    });
  };

  const handleConfirm = () => {
    setLoadingComment(true);
    createNote(
      {
        intentionId: rows?.id,
        note: tempNote,
        adminOnly: options.admin,
        pinToTop: options.pinTop,
      },
      (res: { data: IntentionNoteStructure }) => {
        setNotes(options.pinTop ? [res.data, ...notes] : [...notes, res.data]);
        setLoadingComment(false);
        setTempNote("");
      },
      (error: Error) => {
        console.error("Error creating note:", error);
        setLoadingComment(false);
      }
    );
  };

  const handleDelete = (id: string) => {
    deleteNote(
      { intentionNoteId: id },
      () => setNotes(notes.filter((note) => note.intentionNoteId !== id)),
      (error: Error) => console.error("Error deleting note:", error)
    );
  };

  const handleCheckboxChange =
    (option: keyof typeof options) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setOptions({ ...options, [option]: event.target.checked });
    };

  useEffect(() => {
    setOptions({
      admin: false,
      pinTop: false,
    });
  }, []);

  return (
    <>
      <ReusableDialog
        setIsOpen={setOpen}
        isOpen={open}
        maxWidth="md"
        icon={
          notes.length > 0 ? (
            <Tooltip title={notes[notes.length - 1].note}>
              <Message />
            </Tooltip>
          ) : (
            <AddComment />
          )
        }
        title="Change Order Note"
        type="icon"
        content={
          <>
            <DialogContent>
              <Box>
                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                  {notes.map((note) =>
                    !note.adminOnly || state.isAdmin.isAdmin ? (
                      <ListItem
                        key={note.intentionNoteId}
                        secondaryAction={
                          <Stack
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {note.pinToTop && <PushPin />}
                            {note.adminOnly && <AdminPanelSettings />}
                            {note.showDelete && (
                              <IconButton
                                onClick={() =>
                                  handleDelete(note.intentionNoteId)
                                }
                                edge="end"
                                aria-label="delete"
                                sx={{ color: "black" }}
                              >
                                <Delete />
                              </IconButton>
                            )}
                          </Stack>
                        }
                        alignItems="flex-start"
                      >
                        <ListItemText
                          primary={
                            <Stack
                              direction="row"
                              alignItems="flex-end"
                              gap={1}
                            >
                              {note.createdByName}
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="caption"
                                color="text.primary"
                              >
                                {moment(note.createdAt).format(
                                  "MM-DD-YYYY hh:mm A"
                                )}
                              </Typography>
                            </Stack>
                          }
                          secondary={
                            <Typography
                              sx={{ display: "inline" }}
                              component="p"
                              variant="body1"
                              color="text.primary"
                            >
                              {note.note}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ) : null
                  )}
                </List>
                <TextField
                  multiline
                  fullWidth
                  value={tempNote}
                  onChange={(e) => setTempNote(e.target.value)}
                  rows={4}
                  sx={{ mt: 2 }}
                />
                <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={options.pinTop}
                        onChange={handleCheckboxChange("pinTop")}
                      />
                    }
                    label="Pin Note To Top"
                  />
                  {state.isAdmin.isAdmin && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={options.admin}
                          onChange={handleCheckboxChange("admin")}
                        />
                      }
                      label="Admin Note"
                    />
                  )}
                </FormGroup>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpen(false);
                  setOptions({
                    admin: false,
                    pinTop: false,
                  });
                }}
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                disabled={loadingComment || !tempNote}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </>
        }
      />
    </>
  );
};

export default Notes;
