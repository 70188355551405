import Header from "../Header";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignInButton } from "../Buttons/SignInButton";
import { SignOutButton } from "../Buttons/SignOutButton";
// import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import {
  AdminPanelSettings,
  Announcement,
  Badge,
  BookOutlined,
  ChevronLeftOutlined,
  ChevronRightOutlined,
  ExpandLess,
  ExpandMore,
  Home,
  LinkOutlined,
  LinkRounded,
  Menu,
  Person,
  RoomPreferences,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { getChangeOrderLinks, retrieveUserInfo } from "../../apiCalls";
import { useContext, useEffect, useState } from "react";
import { UIContext, UIState } from "../../providers/UIProvider";
import { dispatchError, handleUnchangedNavigation } from "../../common/fx";
import useAccessControl from "../../hooks/useAccessControl";

const Layout = (props: { children: any }) => {
  const isAuthenticated = useIsAuthenticated();
  const theme = useTheme();
  const navigate = useNavigate();
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [adminOpen, setAdminOpen] = useState(false);
  const [relatedLinksOpen, setRelatedLinksOpen] = useState(false);
  const [relatedLinks, setRelatedLinks] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { instance, accounts } = useMsal();

  const AdminPanelMenu = useAccessControl("UserAdmin", "SetUserAccess");
  const RelatedLinksMenu = useAccessControl(
    "ChangeOrderLinks",
    "GetChangeOrderLinks"
  );
  const RolesAccessMenu = useAccessControl("SecurityAdmin", "ListRoleAccess");
  const UserManagementMenu = useAccessControl("UserAdmin", "GetUsers");
  const getChangeOrderLinksApiAccess = useAccessControl(
    "ChangeOrderLinks",
    "GetChangeOrderLinks"
  );

  const userHasAdminRole = () => {
    // Use optional chaining to safely access properties.
    retrieveUserInfo(
      {
        userId: accounts[0]?.localAccountId,
      },
      (res: any) =>
        dispatch({
          type: "AdminStatus",
          payload: res.data,
        }),
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  };

  useEffect(() => {
    userHasAdminRole();
    getChangeOrderLinksApiAccess &&
      getChangeOrderLinks(
        (res: any) => setRelatedLinks(res.data),
        (err: any) => dispatch(dispatchError(err.response.data))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChangeOrderLinksApiAccess]);

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  // const handleNavigate = (pathTo: string) => {};

  /* interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }*/

  /*const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - 244px)`,
      marginLeft: `244px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));*/

  const appBarHeight = theme.mixins.toolbar.minHeight + theme.spacing(2); // Example adjustment, may need fine-tuning

  const getEnvironmentFromURL = (): string => {
    // Assuming window.location.href gives the current URL
    const url = new URL(window.location.href);
    const hostname = url.hostname;
    const subdomain = hostname.split(".")[0]; // Get the subdomain part of the hostname

    switch (subdomain) {
      case "localhost":
        return "Local Environment";
      case "co-dev":
        return "Development Environment";
      case "co-tst":
        return "Test Environment";
      case "co-uat":
        return "User Test Environment";
      default:
        return ""; // Fallback in case the URL doesn't match
    }
  };

  return (
    <Box>
      <Header
        headerLeft={
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <Menu />
            </IconButton>
          </>
        }
        headerRight={
          isAuthenticated ? (
            <Stack
              alignContent={"center"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"row"}
              gap={1}
            >
              {getEnvironmentFromURL() !== "" && (
                <Box
                  padding={2}
                  mr={(theme) => theme.spacing(26)}
                  style={{
                    backgroundColor: "#e56d29",
                  }}
                >
                  <Typography variant="h5">
                    {getEnvironmentFromURL()}
                  </Typography>
                </Box>
              )}
              <IconButton
                disabled={
                  location.pathname === `/edit` && !state.changeOrder.address
                }
                onClick={() => {
                  const objectToUrlParams = (
                    obj: Record<string, any>
                  ): string => {
                    const params = Object.entries(obj).map(
                      ([key, value]) =>
                        `${encodeURIComponent(key)}=${encodeURIComponent(
                          value
                        )}`
                    );
                    return params.join("&");
                  };
                  if (location.pathname === `/job-summary`) {
                    console.log(state.selectedJob);
                    window.open(
                      `/catalog/?${objectToUrlParams({
                        community: state.selectedJob.community,
                        jobID: state.selectedJob.jobId,
                        jobNumber: state.selectedJob.jobNumber,
                        planElevationId: state.selectedJob.planElevationId,
                        planElevationNumber:
                          state.selectedJob.planElevationNumber,
                        planId: state.selectedJob.planId,
                        planNumber: state.selectedJob.planNumber,
                        projectID:
                          state.selectedJob.projectID ??
                          state.selectedJob.projectId,
                        projectNumber: state.selectedJob.projectNumber,
                      })}`,
                      "_blank"
                    );
                  } else if (location.pathname === `/edit`) {
                    window.open(
                      `/catalog/?${objectToUrlParams(
                        state.changeOrder.address
                      )}`,
                      "_blank"
                    );
                  } else if (location.pathname === `/view`) {
                    window.open(
                      `/catalog/?${objectToUrlParams(
                        state.changeOrder.address
                      )}`,
                      "_blank"
                    );
                  } else window.open("/catalog", "_blank");
                }}
                size="small"
              >
                <BookOutlined
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            </Stack>
          ) : (
            <SignInButton />
          )
        }
      />

      <Drawer
        sx={{
          width: 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 240,
            boxSizing: "border-box",
          },
        }}
        onClose={handleDrawerClose}
        variant="temporary"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftOutlined />
            ) : (
              <ChevronRightOutlined />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Stack
          sx={{ height: "100%" }}
          direction={"column"}
          justifyContent={"space-between"}
        >
          <List>
            {/* Home */}
            <ListItem disablePadding>
              <ListItemButton
                selected={location.pathname === `/`}
                onClick={() =>
                  handleUnchangedNavigation(
                    "/",
                    state.changeOrder.status,
                    navigate,
                    dispatch
                  )
                }
              >
                <ListItemIcon>
                  <Home
                    sx={{
                      color: (theme) =>
                        location.pathname === `/`
                          ? theme.palette.primary.main
                          : null,
                      backgroundColor: "transparent",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={"Home"} />
              </ListItemButton>
            </ListItem>

            {/* Admin Panel (non-clickable, but expands to show children) */}
            {AdminPanelMenu && (
              <>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={location.pathname.startsWith(`/admin`)}
                    onClick={() => setAdminOpen(!adminOpen)}
                  >
                    <ListItemIcon>
                      <AdminPanelSettings
                        sx={{
                          color: (theme) =>
                            location.pathname.startsWith(`/admin`)
                              ? theme.palette.primary.main
                              : null,
                          backgroundColor: "transparent",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={"Admin Panel"} />
                    {adminOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                </ListItem>

                <Collapse in={adminOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {/* Each child of Admin Panel */}
                    {UserManagementMenu && (
                      <ListItem disablePadding>
                        <ListItemButton
                          sx={{ pl: 4 }}
                          onClick={() => navigate("/admin/usermanagement")}
                          selected={
                            location.pathname === `/admin/usermanagement`
                          }
                        >
                          <ListItemIcon>
                            <Person
                              sx={{
                                color: (theme) =>
                                  location.pathname.startsWith(
                                    `/admin/usermanagement`
                                  )
                                    ? theme.palette.primary.main
                                    : null,
                                backgroundColor: "transparent",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary="User Management" />
                        </ListItemButton>
                      </ListItem>
                    )}
                    {RolesAccessMenu && (
                      <ListItem disablePadding>
                        <ListItemButton
                          sx={{ pl: 4 }}
                          onClick={() => navigate("admin/roles")}
                          selected={location.pathname === `/admin/roles`}
                        >
                          <ListItemIcon>
                            <Badge
                              sx={{
                                color: (theme) =>
                                  location.pathname.startsWith(`/admin/roles`)
                                    ? theme.palette.primary.main
                                    : null,
                                backgroundColor: "transparent",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary="Roles & Access" />
                        </ListItemButton>
                      </ListItem>
                    )}

                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => navigate("admin/systemnotifications")}
                        selected={
                          location.pathname === `/admin/systemnotifications`
                        }
                      >
                        <ListItemIcon>
                          <Announcement
                            sx={{
                              color: (theme) =>
                                location.pathname.startsWith(
                                  `/admin/systemnotifications`
                                )
                                  ? theme.palette.primary.main
                                  : null,
                              backgroundColor: "transparent",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="System Notifications" />
                      </ListItemButton>
                    </ListItem>

                    {/* <ListItem disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        selected={location.pathname === `/admin/community`}
                      >
                        <ListItemText primary="Community Management" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        selected={location.pathname === `/admin/templates`}
                      >
                        <ListItemText primary="Template Management" />
                      </ListItemButton>
                    </ListItem> */}
                  </List>
                </Collapse>
              </>
            )}

            <ListItem disablePadding>
              <ListItemButton
                onClick={() => navigate("/templatemanagement")}
                selected={location.pathname === `/templatemanagement`}
              >
                <ListItemIcon>
                  <RoomPreferences
                    sx={{
                      color: (theme) =>
                        location.pathname.startsWith(`/templatemanagement`)
                          ? theme.palette.primary.main
                          : null,
                      backgroundColor: "transparent",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Template Management" />
              </ListItemButton>
            </ListItem>

            {/* Option Catalog */}
            <ListItem disablePadding>
              <ListItemButton
                selected={location.pathname === `/catalog`}
                onClick={() => {
                  const objectToUrlParams = (
                    obj: Record<string, any>
                  ): string => {
                    const params = Object.entries(obj).map(
                      ([key, value]) =>
                        `${encodeURIComponent(key)}=${encodeURIComponent(
                          value
                        )}`
                    );
                    return params.join("&");
                  };
                  if (location.pathname === `/job-summary`) {
                    console.log(state.selectedJob);
                    window.open(
                      `/catalog/?${objectToUrlParams({
                        community: state.selectedJob.community,
                        jobID: state.selectedJob.jobId,
                        jobNumber: state.selectedJob.jobNumber,
                        planElevationId: state.selectedJob.planElevationId,
                        planElevationNumber:
                          state.selectedJob.planElevationNumber,
                        planId: state.selectedJob.planId,
                        planNumber: state.selectedJob.planNumber,
                        projectID:
                          state.selectedJob.projectID ??
                          state.selectedJob.projectId,
                        projectNumber: state.selectedJob.projectNumber,
                      })}`,
                      "_blank"
                    );
                  } else if (location.pathname === `/edit`) {
                    window.open(
                      `/catalog/?${objectToUrlParams(
                        state.changeOrder.address
                      )}`,
                      "_blank"
                    );
                  } else if (location.pathname === `/view`) {
                    window.open(
                      `/catalog/?${objectToUrlParams(
                        state.changeOrder.address
                      )}`,
                      "_blank"
                    );
                  } else window.open("/catalog", "_blank");
                }}
              >
                <ListItemIcon>
                  <BookOutlined
                    sx={{
                      color: (theme) =>
                        location.pathname === `/catalog`
                          ? theme.palette.primary.main
                          : null,
                      backgroundColor: "transparent",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={"Option Catalog"} />
              </ListItemButton>
            </ListItem>
            <Divider />
            {/* Related links */}

            {RelatedLinksMenu && (
              <>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={false}
                    onClick={() => setRelatedLinksOpen(!relatedLinksOpen)}
                  >
                    <ListItemIcon>
                      <LinkOutlined
                        sx={{
                          color: (theme) =>
                            location.pathname.startsWith(`/admin`)
                              ? theme.palette.primary.main
                              : null,
                          backgroundColor: "transparent",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={"Related Links"} />
                    {relatedLinksOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                </ListItem>
                <Collapse in={relatedLinksOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {/* Each child of Related Links */}
                    {relatedLinks.map(
                      (
                        obj: {
                          displayName: string;
                          changeOrderLinkUrl: string;
                        },
                        oIx
                      ) => (
                        <ListItem disablePadding key={oIx}>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() =>
                              window.open(obj?.changeOrderLinkUrl, "_blank")
                            }
                          >
                            <ListItemIcon>
                              <LinkRounded />
                            </ListItemIcon>
                            <ListItemText primary={obj?.displayName} />
                          </ListItemButton>
                        </ListItem>
                      )
                    )}
                  </List>
                </Collapse>
              </>
            )}
          </List>
          <List>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate("/");
                  handleDrawerClose();
                }}
              >
                <SignOutButton />
              </ListItemButton>
            </ListItem>
          </List>
        </Stack>
      </Drawer>
      {location.pathname === "/admin" ? (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: `${appBarHeight}px`, // Apply top margin to push the content down
          }}
        >
          {props.children}
        </Box>
      ) : (
        <>{props.children}</>
      )}
    </Box>
  );
};

export default Layout;
