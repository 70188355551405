import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  GridRowModesModel,
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { Tooltip, TextField, LinearProgress } from "@mui/material";
import { numberWithCommas } from "../../utils/formatMoney";
import Notes from "../../modals/Notes";
import { UIContext, UIState } from "../../providers/UIProvider";
import { changeOrderOptions } from "../../apiCalls";
import { isEmpty } from "lodash";
import GridCustomFooter from "../../components/GridCustomFooter";

//type Props = { rowUpdate: any; rows: any; setRows: any };

function TextInputCell(props: GridRenderCellParams<any, string>) {
  const { value } = props;

  return (
    <Box sx={{ width: "100%" }}>
      <TextField variant="standard" fullWidth defaultValue={value} />
    </Box>
  );
}
const renderTextInputCell: GridColDef["renderCell"] = (params) => {
  return <TextInputCell {...params} />;
};

const Summary = () => {
  const [state, dispatch] = React.useContext<UIState | any>(UIContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = React.useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const confirmColumns = [
    {
      field: "optionAbbreviation",
      headerName: "Option",
      width: 180,
      editable: true,
      type: "singleSelect",
    },
    {
      field: "optionCode",
      headerName: "Option Code",
      width: 130,
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
      editable: false,
    },
    {
      field: "notes",
      headerName: "Notes",
      renderCell: (params: any) => (
        <Tooltip title="View Notes">
          <Notes
            setRows={dispatch}
            rows={state.changeOrder.pendingItems}
            note={params}
          />
        </Tooltip>
      ),
    },
    {
      field: "quantity",
      headerName: "QTY",
      editable: true,
      width: 130,
      renderEditCell: renderTextInputCell,
    },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      width: 180,
      valueFormatter: (params: any) => `$${numberWithCommas(params.value)}`,
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      width: 180,
      valueGetter: (params: any) => {
        return `${(params.row.quantity * params.row.unitPrice).toFixed(2)}`;
      },
      valueFormatter: (params: any) => `$${numberWithCommas(params.value)}`,
    },
  ];

  return (
    <Box
      width={"100%"}
      sx={{
        mt: (theme) => theme.spacing(4),
        mb: (theme) => theme.spacing(4),
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box>
          <Typography component="h1" variant="h6" textAlign="left">
            Submit Change Order
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          height: 300,
          width: "100%",
          mt: (theme) => theme.spacing(2),
        }}
      >
        {state.changeOrder.pendingItems && (
          <DataGridPro
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            disableRowSelectionOnClick
            columns={confirmColumns}
            sx={{
              "& .css-1iledgx-MuiFormControl-root": {
                marginBottom: "0px",
              },
            }}
            rowModesModel={rowModesModel}
            // onRowModesModelChange={handleRowModesModelChange}
            getRowHeight={() => "auto"}
            rows={state.changeOrder.pendingItems.filter(
              (obj: changeOrderOptions) => !isEmpty(obj.description)
            )}
            rowCount={state.changeOrder.pendingItems.length}
            pagination
            slots={{
              loadingOverlay: LinearProgress,
              footer: GridCustomFooter,
            }}
            loading={loading}
          />
        )}
      </Box>
    </Box>
  );
};

export default Summary;
