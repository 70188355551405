import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import {
  Stack,
  Tooltip,
  //SelectChangeEvent,
  //TextField,
  Button,
  //DialogContent,
  //DialogActions,
  LinearProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
//import NoRows from "../../components/DataGrid/animations/NoRows";
//import MessageIcon from "@mui/icons-material/Message";
import { numberWithCommas } from "../../utils/formatMoney";
//import ReusableDialog from "../../components/Dialog";
import SubmitCancellationChangeOrder from "../../modals/SubmitCancellationChangeOrder";
import Notes from "../../modals/Notes";
function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ mt: (theme) => theme.spacing(12), mb: (theme) => theme.spacing(4) }}
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://highlandhomes.com/">
        Highland Homes
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

//const ITEM_HEIGHT = 48;
//const ITEM_PADDING_TOP = 8;
/*const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};*/

export default function Cancel() {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = React.useState<boolean>(false);
  //const [notesOpen, setNotesOpen] = React.useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [status, setStatus] = React.useState<string[]>([
    "Pending",
    "Submitted",
  ]);

  const arrayOfObjects = [
    {
      id: 1,
      status: "submitted",
      changeOrderNumber: "123456789",
      category: "Exterior Doors",
      option: "Bedroom",
      optionCode: "1250",
      description: "Peep hole and door knocker",
      notes: "This is a test note",
      unitPrice: 7400,
      quantity: 1,
    },
    {
      id: 2,
      status: "pending",
      changeOrderNumber: "1234567891",
      category: "Electrical",
      option: "Media Room",
      optionCode: "1100",
      description: "Additional LAN Outlet",
      notes: "This is a test note",
      unitPrice: 7400,
      quantity: 1,
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [documents, setDocuments] = React.useState<
    {
      id: number;
      status: string;
      changeOrderNumber: string;
      category: string;
      option: string;
      optionCode: string;
      description: string;
      notes: string;
      unitPrice: number;
      quantity: number;
    }[]
  >(arrayOfObjects);

  // const fetchDocuments = () => {
  //   setLoading(true);
  //   axiosInstance
  //     .get(`/api/documents/PendingApproval`)
  //     .then((res) => {
  //       setDocuments(res.data.results);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.error(err);
  //     });
  // };

  // const handleDownloadItems = (fileName: string) => {
  //   axiosInstance
  //     .get(`/api/document/${fileName}`)
  //     .then((res) => {
  //       window.open(res.data.results.sasUri, "_blank", "noreferrer");
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  /*const handleStatusChange = (event: SelectChangeEvent<typeof status>) => {
    const {
      target: { value },
    } = event;
    setStatus(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };*/

  // const handleCommunityChange = (event: SelectChangeEvent) => {
  //   setCommunity(event.target.value as string);
  // };

  const columns: GridColDef[] = [
    {
      field: "status",
      headerName: "Status",
      width: 130,
    },
    {
      field: "changeOrderNumber",
      headerName: "Change Order #",
      width: 130,
    },
    {
      field: "category",
      headerName: "Category",
      width: 130,
    },
    {
      field: "option",
      headerName: "Option",
      width: 130,
      renderCell: (params) => (
        <Box
          sx={{
            textTransform: "capitalize",
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: "optionCode",
      headerName: "Option Code",
      width: 180,
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      renderCell: (params) => (
        <Box
          sx={{
            textTransform: "capitalize",
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: "notes",
      headerName: "Notes",
      renderCell: (params) => (
        <Tooltip title="View Notes">
          <Notes note={params.value} />
        </Tooltip>
      ),
    },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      width: 180,
      valueFormatter: (params) => `$${numberWithCommas(params.value)}`,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 180,
    },
  ];

  React.useEffect(() => {
    // fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth={false}>
        <CssBaseline />
        <Box
          width={"100%"}
          sx={{
            mt: (theme) => theme.spacing(4),
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography component="h1" variant="h6" textAlign="left">
            Submitted Change Order Items
          </Typography>
          <Box
            sx={{
              height: "68vh",
              width: "100%",
              mt: (theme) => theme.spacing(2),
            }}
          >
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                py: (theme) => theme.spacing(2),
              }}
            >
              <Stack
                width={"50%"}
                direction="row"
                alignItems="flex-start"
                spacing={2}
              >
                <FormControl sx={{ my: 1, minWidth: 300 }}>
                  <InputLabel>Status</InputLabel>
                  <Select
                    id="demo-multiple-chip"
                    multiple
                    value={status}
                    onChange={handleStatusChange}
                    input={<OutlinedInput label="Status" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            variant="outlined"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onDelete={(e) => {
                              e.stopPropagation();
                              console.log("remove");
                            }}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="Pending">
                      <Checkbox checked={status.indexOf("Pending") > -1} />
                      Pending
                    </MenuItem>
                    <MenuItem value="Submitted">
                      <Checkbox checked={status.indexOf("Submitted") > -1} />
                      Submitted
                    </MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Box> */}
            {documents && (
              <DataGridPro
                initialState={{
                  pagination: { paginationModel: { pageSize: 25 } },
                }}
                disableRowSelectionOnClick
                columns={columns}
                rows={documents}
                rowCount={documents.length}
                checkboxSelection
                pagination
                slots={{
                  loadingOverlay: LinearProgress,
                }}
                loading={loading}
              />
            )}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
              mt={2}
            >
              <Button
                variant="outlined"
                onClick={() => navigate("/change-orders")}
              >
                Back
              </Button>
              <SubmitCancellationChangeOrder />
            </Stack>
          </Box>
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
