import {
  Dialog,
  DialogTitle,
  Theme,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { GetSystemMessages } from "../../apiCalls";
import { UIContext } from "../../providers/UIProvider";
import { dispatchError } from "../../common/fx";

type Notification = {
  type: "KnownIssue" | "NewFeature" | "SystemMaintenance";
  title: string;
  message: string;
  expiresAt: any;
  id: string;
};

type Props = {};

const NotificationDialog = (props: Props) => {
  const [dispatch]: any = useContext(UIContext);

  const [open, setOpen] = useState(false);
  const [notificationArr, setNotificationArr] = useState<Notification[]>([]);
  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(0);

  const fetchSystemMessages = () => {
    GetSystemMessages(
      (res: any) => {
        console.log(res.data);
        setNotificationArr(res.data);
        if (res.data.length > 0) {
          setOpen(true); // Open the dialog when notifications are fetched
        }
      },
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  };

  useEffect(() => {
    fetchSystemMessages();
    //@ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    if (currentNotificationIndex < notificationArr.length - 1) {
      // Move to the next notification
      setCurrentNotificationIndex((prevIndex) => prevIndex + 1);
    } else {
      // Close the dialog if no more notifications
      setOpen(false);
    }
  };

  const currentNotification = notificationArr[currentNotificationIndex];

  return (
    <Dialog
      maxWidth={"md"}
      open={open}
      data-testid="dialog-wrapper"
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle
        data-testid={"dialog-title"}
        sx={(theme: Theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        })}
      >
        {currentNotification?.title || "Notification"}
      </DialogTitle>
      <DialogContent>
        {currentNotification?.message ? (
          <div
            dangerouslySetInnerHTML={{ __html: currentNotification.message }}
          />
        ) : (
          "No message available."
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationDialog;
