import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Stack,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Tooltip,
} from "@mui/material";
import { Edit, SearchOutlined } from "@mui/icons-material";
import {
  DataGridPro,
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { getAllRoles } from "../../apiCalls";
import { UIContext, UIState, RoleType } from "../../providers/UIProvider";
import { dispatchError } from "../../common/fx";
import { mainTheme } from "../../styles/MainTheme";
import useAccessControl from "../../hooks/useAccessControl";

const AdminRoles = () => {
  const [rolesList, setRolesList] = useState<RoleType[] | null>(null);
  const [filt, setFilt] = useState({
    items: [],
    quickFilterValues: [""],
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const getAllRolesAccess = useAccessControl("UserAdmin", "GetRoles");

  const navigate = useNavigate();
  const rolesColumns: GridColDef[] = [
    {
      field: "description",
      flex: 10,
      headerName: "Role Name",
      renderCell: (params: GridRenderCellParams) => (
        <a
          href={`/admin/role/?roleName=${params.row.name}`}
          style={{
            color: mainTheme.palette.primary.main,
            cursor: "pointer",
            textDecoration: "none",
          }}
        >
          {params.value}
        </a>
      ),
    },
    {
      editable: false,
      field: "actions",
      filterable: false,
      flex: 0.5,
      headerName: "",
      hideable: true,
      pinnable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <Tooltip title={`View User ${params.row.userPrincipalName}`}>
            <IconButton
              color="primary"
              sx={{ p: "10px" }}
              aria-label={`View User ${params.row.userPrincipalName}`}
              onClick={() => {
                console.log(params);
                navigate(`/admin/role/?roleName=${params.row.name}`);
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        </>
      ),
      resizable: false,
      sortable: false,
      width: 50,
    },
  ];

  useEffect(() => {
    dispatch({
      type: "Navbar",
      payload: { title: `Roles & Access` },
    });
    getAllRolesAccess &&
      getAllRoles(
        (response: any) => {
          setRolesList(response.data);
        },
        (err: any) => {
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail:
                err.response.data.detail ??
                err.response.data.errors.OperationCanceledException[0],
              data: err.response.data,
            })
          );
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Stack
        sx={{
          minHeight: "40rem",
        }}
        gap={2}
        flexDirection={"row"}
      >
        <Stack
          sx={{ minHeight: "40rem", width: "100%", position: "relative" }}
          gap={2}
          p={2}
          flexDirection={"column"}
        >
          <Stack
            mt={2}
            gap={2}
            sx={{
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: { xs: "center", md: "space-between" },
            }}
          >
            <Box
              sx={{
                maxWidth: {
                  xs: "100%",
                  md: "45rem",
                },
              }}
              width={"100%"}
              gap={2}
              display={"flex"}
              alignItems={"center"}
            >
              <TextField
                fullWidth
                size="small"
                label="Search"
                variant="outlined"
                onChange={(e: any) =>
                  setFilt({ ...filt, quickFilterValues: [e.target.value] })
                }
                placeholder="Search..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined color="info" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>

          <DataGridPro
            sx={{
              marginTop: (theme) => theme.spacing(2),
              height: "100%",
              minHeight: "20rem",
              ".MuiDataGrid-columnHeadersInner": {
                backgroundColor: "#1976d2",
                color: "white",
              },
            }}
            pagination
            disableRowSelectionOnClick
            columns={rolesColumns}
            rows={rolesList || []}
            getRowId={(row) => row.id}
            rowCount={rolesList?.length}
            filterModel={filt as GridFilterModel | undefined}
            onFilterModelChange={(newFilterModel) =>
              setFilt(newFilterModel as any)
            }
            slots={{
              loadingOverlay: LinearProgress,
            }}
            loading={rolesList === null}
          />
        </Stack>
      </Stack>
    </div>
  );
};

export default AdminRoles;
