//import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./styles/index.css";
import App from "./App.jsx";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { BrowserRouter } from "react-router-dom";
import { UIContextProvider } from "./providers/UIProvider";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
// @ts-ignore
export const msalInstance = new PublicClientApplication(msalConfig);

const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);

root.render(
  <MsalProvider instance={msalInstance}>
    <BrowserRouter>
      <UIContextProvider>
        <App />
      </UIContextProvider>
    </BrowserRouter>
  </MsalProvider>
);
