import React, { useState, useContext, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { mainTheme } from "../../styles/MainTheme";
import { ExpandMore, UnfoldMore, UnfoldLess } from "@mui/icons-material";
import { listRoleAccess, toggleRoleAccess, getAllRoles } from "../../apiCalls";
import {
  UIContext,
  UIState,
  RoleType,
  RoleController,
  RolePermission,
} from "../../providers/UIProvider";
import { dispatchError } from "../../common/fx";
import Loading from "../../components/Loading";
import "./roles.scss";
import useAccessControl from "../../hooks/useAccessControl";

const EditRole = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [searchParams] = useSearchParams();
  const [roleName, setRoleName] = useState<string | null>(
    searchParams.get("roleName")
  );
  const [rolesList, setRolesList] = useState<RoleType[] | null>(null);
  const [role, setRole] = useState<RoleType | null>(null);
  const [isSummarySticky, setIsSummarySticky] = useState<boolean>(false);
  const getAllRolesAccess = useAccessControl("UserAdmin", "GetRoles");
  const listRoleAccessCheck = useAccessControl(
    "SecurityAdmin",
    "ListRoleAccess"
  );
  const toggleRoleAccessCheck = useAccessControl(
    "SecurityAdmin",
    "ToggleRoleAccess"
  );

  const navigate = useNavigate();

  useEffect(() => {
    getAllRolesAccess &&
      getAllRoles(
        (response: any) => {
          setRolesList(response.data);
        },
        (err: any) => {
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail:
                err.response.data.detail ??
                err.response.data.errors.OperationCanceledException[0],
              data: err.response.data,
            })
          );
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllRolesAccess]);

  useEffect(() => {
    setRole(null);
    if (roleName) {
      updateTitle();
      listRoleAccessCheck &&
        listRoleAccess(
          roleName,
          (res: any) => {
            const newRole = { ...res.data };
            newRole.access = newRole.access.map((controller: any) => ({
              ...controller,
              isExpanded: true,
            }));
            setRole(newRole);
          },
          (err: any) => {
            dispatch(
              dispatchError({
                message: err.message,
                statusText: err.response.statusText,
                title: err.response.data.title,
                status: err.response.status,
                detail:
                  err.response.data.detail ??
                  err.response.data.errors.OperationCanceledException[0],
                data: err.response.data,
              })
            );
          }
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleName, listRoleAccessCheck]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 70;
      if (window.scrollY > scrollThreshold && !isSummarySticky) {
        setIsSummarySticky(true);
      } else if (window.scrollY <= scrollThreshold && isSummarySticky) {
        setIsSummarySticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSummarySticky]);

  useEffect(() => {
    roleName && updateTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesList]);

  const updateTitle = () => {
    const roleDesc = rolesList?.find(
      (role: RoleType) => role.name === roleName
    )?.description;
    if (roleDesc) {
      dispatch({
        type: "Navbar",
        payload: { title: `Roles & Access : ${roleDesc}` },
      });
    }
  };

  const handleUpdateAll = (setExpandedTo: boolean) => {
    if (!role) return;
    const newRole = { ...role };
    const newControllers =
      (newRole.access &&
        newRole.access.map((controller: RoleController) => ({
          ...controller,
          isExpanded: setExpandedTo,
        }))) ||
      [];
    newRole.access = newControllers;
    setRole(newRole);
  };

  const handleExpanderChange =
    (controllerName: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (!role) return;
      const newRole = { ...role };
      const newControllers = (newRole.access && [...newRole.access]) || [];
      const newControllerIx = newControllers?.findIndex(
        (controller: RoleController) =>
          controller.controllerName === controllerName
      );
      if (typeof newControllerIx === "undefined") return;
      newControllers[newControllerIx].isExpanded =
        !newControllers[newControllerIx].isExpanded;
      newRole.access = newControllers;
      setRole(newRole);
    };

  const handleRoleChange = (event: SelectChangeEvent) => {
    const newRole = rolesList?.find(
      (role: RoleType) => role.id === event.target.value
    );
    setRoleName(newRole?.name || null);
    navigate(`/admin/role/?roleName=${newRole?.name}`, { replace: true });
  };

  const handleToggleAction = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!role) return false;
    const newActive = event.target.checked;
    const switchId = event.target.id.split("-");
    const controller: string = switchId[0];
    const action = switchId[1];
    const actionDesc = event.target.checked ? "activated" : "deactivated";

    toggleRoleAccessCheck &&
      toggleRoleAccess(
        {
          roleName: role?.name || "",
          controller: controller,
          action: action,
        },
        (res: any) => {
          const controllerIx = role?.access.findIndex(
            (roleController: RoleController) =>
              roleController.controllerName === controller
          );
          if (typeof controllerIx === "undefined") return false;
          const actionIx = role?.access[controllerIx].actions.findIndex(
            (roleAction: RolePermission) => roleAction.action === action
          );
          if (typeof actionIx === "undefined") return false;
          const newRole = { ...role };
          newRole.access[controllerIx].actions[actionIx].active = newActive;
          setRole(newRole);
          dispatch({
            type: "Snackbar",
            payload: {
              show: true,
              message: `Action ${action} has been successfully ${actionDesc} `,
              severity: "success",
            },
          });
        },
        (err: any) => {
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail:
                err.response.data.detail ??
                err.response.data.errors.OperationCanceledException[0],
              data: err.response.data,
            })
          );
        }
      );
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Container
        sx={{
          my: mainTheme.spacing(2),
        }}
        component="main"
        maxWidth={false}
      >
        <CssBaseline />
        {role && (
          <>
            <Stack
              className={`summaries-wrapper${isSummarySticky ? " sticky" : ""}`}
              sx={{
                width: "100%",
                height: "100%",
              }}
              gap={2}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Stack
                alignItems={"center"}
                alignContent={"center"}
                gap={2}
                flexDirection={"row"}
                sx={{
                  alignItems: "top",
                  border: 1,
                  borderColor: (mainTheme) => mainTheme.palette.grey[300],
                  borderTop: 4,
                  borderRadius: "10px",
                  width: "100%",
                  borderTopColor: (mainTheme) => mainTheme.palette.primary.main,
                  justifyContent: { xs: "center", md: "space-between" },
                  flexDirection: { xs: "column", md: "row" },
                  maxWidth: { xs: "100%", lg: "40%" },
                  py: 0.5,
                  px: 2,
                }}
              >
                <div className="summary-details">
                  {rolesList ? (
                    <FormControl
                      size="small"
                      sx={{ my: "8px", width: "350px" }}
                    >
                      <InputLabel id="roles-label">Role:</InputLabel>
                      <Select
                        labelId="roles-label"
                        id="roles-dropdown"
                        value={role.id}
                        label="Role:"
                        onChange={handleRoleChange}
                      >
                        {rolesList.map((role: RoleType, rIx) => (
                          <MenuItem value={role.id} key={`role-${rIx}`}>
                            {role.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <Skeleton variant="text" height={24} width={160} />
                  )}
                </div>
                <div className="summary-details">
                  <p style={{ margin: "0 auto .5rem" }}>
                    <strong>Role Key:</strong>
                  </p>
                  <div className="summary-details--info">{role.name}</div>
                </div>
              </Stack>
              <Stack
                alignItems={"center"}
                alignContent={"center"}
                gap={2}
                flexDirection={"row"}
              >
                <Button variant="text" onClick={() => handleUpdateAll(true)}>
                  <UnfoldMore /> Expand all
                </Button>{" "}
                |
                <Button variant="text" onClick={() => handleUpdateAll(false)}>
                  <UnfoldLess /> Collapse All
                </Button>
              </Stack>
            </Stack>
            <Box sx={{ margin: "30px 0" }}>
              {role.access.map((controller: RoleController) => (
                <Accordion
                  className="role--accordion"
                  expanded={controller.isExpanded}
                  key={`controller-${controller.controllerName}`}
                  onChange={handleExpanderChange(controller.controllerName)}
                >
                  <AccordionSummary
                    className="role--accordion--summary"
                    expandIcon={<ExpandMore />}
                    aria-controls={`role-${role.name}`}
                    id={`role-${role.name}`}
                  >
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <Typography>{controller.controllerName}</Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails className="role--accordion--content">
                    {controller.actions.map((action: RolePermission) => (
                      <div
                        className="role--permission"
                        key={`permission-${action.action}`}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={!toggleRoleAccessCheck}
                                checked={action.active}
                                id={`${controller.controllerName}-${action.action}`}
                                onChange={handleToggleAction}
                              />
                            }
                            label={action.action}
                          />
                        </FormGroup>
                        <p>{action.description}</p>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </>
        )}
        {role === null && <Loading />}
        <footer>
          <Box width={"100%"} display={"flex"} alignItems={"center"}>
            <Button
              onClick={() => navigate("/admin/roles/")}
              variant="outlined"
            >
              Back
            </Button>
          </Box>
        </footer>
      </Container>
    </ThemeProvider>
  );
};
export default EditRole;
