import { useState } from "react";
import ReusableDialog from "../../components/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

type Props = {};

const SubmitCancellationChangeOrder = (props: Props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  /*const [step, setStep] = useState({
    currentStep: 0,
    stepTitle: "title 0",
  });*/

  return (
    <>
      <ReusableDialog
        setIsOpen={setOpen}
        isOpen={open}
        maxWidth={"md"}
        content={
          <>
            <DialogContent>
              <Box padding={(theme) => theme.spacing(2)}>
                <Typography textAlign="center">
                  You are about to submit this change order, are you sure?
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => setOpen(false)}
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setOpen(false);
                  navigate("/selected");
                }}
                variant="contained"
                color="primary"
              >
                Submit Cancellation
              </Button>
            </DialogActions>
          </>
        }
        buttonText="Submit Cancellation CO"
        title="Submission Confirmation"
        buttonColor="error"
      />
    </>
  );
};

export default SubmitCancellationChangeOrder;
