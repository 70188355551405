import { useContext } from "react";
import {
  Stack,
  Typography,
} from "@mui/material";
import {
  GridFooterContainer,
  GridFooter,
} from "@mui/x-data-grid-pro";
import {
  HighlandCategoryOption,
  OptionTypes,
  UIContext,
  UIState,
} from "../../providers/UIProvider";
import { numberWithCommas } from "../../utils/formatMoney";

type Props = {
  highlandCategory:HighlandCategoryOption | null
}

export default function CustomFooter(props:Props) {
  const [state] = useContext<UIState | any>(UIContext);
  const totalLabel = props.highlandCategory === null ? 'Change Order Total' : `${props.highlandCategory.label} Subtotal`;

  const itemsToSum = props.highlandCategory !== null ?
    state.changeOrder.pendingItems.filter((item:OptionTypes) => item.highlandCategory.key === props.highlandCategory!.key)
    : state.changeOrder.pendingItems;
  const totalAmount = itemsToSum.reduce(
    (
      acc: any,
      item: {
        quantity: string;
        unitPrice: number;
      }
    ) => {
      return acc + Number(item.quantity) * item.unitPrice;
    },
    0
  );

  return (
    <GridFooterContainer>
      <Stack
        display="flex"
        justifyContent={"flex-end"}
        sx={{
          width: "25%",
        }}
      >
        <Typography ml={(theme) => theme.spacing(2)}>
          {totalLabel}: ${numberWithCommas(totalAmount.toFixed(2))}
        </Typography>
      </Stack>
      {/* Add what you want here */}
      <GridFooter
        sx={{
          border: "none", // To delete double border.
        }}
      />
    </GridFooterContainer>
  );
};