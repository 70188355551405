import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Theme,
  Typography,
} from "@mui/material";
import { Warning } from "@mui/icons-material";

type Props = {
  message: string;
  open: boolean;
  title: string;
  setOpen: (state: boolean) => void;
};

const WarningModal: React.FC<Props> = ({ open, message, setOpen, title }) => {
  const handleConfirm = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle
        data-testid={"dialog-title"}
        sx={(theme: Theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        })}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <Box p={2}>
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            gap={1}
            direction={"column"}
          >
            <Warning
              sx={{
                fontSize: "3rem",
              }}
              color="error"
            />
            <Typography variant="inherit" color="text.primary">
              {message}
            </Typography>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningModal;
