import { useContext, useEffect } from "react";
import { Stack } from "@mui/material";
import { UIContext, UIState } from "../../providers/UIProvider";
import { communityDropdownApi } from "../../apiCalls";
import UserList from "./UserList";
import useAccessControl from "../../hooks/useAccessControl";

type Props = {};

const Index = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  //const [loadingBuyerName, setLoadingBuyerNames] = useState(false);
  /*const [userData, setUserData] = useState({
    selectedCommunities: [],
  });*/
  const communityDropdownApiAccess = useAccessControl(
    "Community",
    "GetActiveCommunities"
  );

  const fetchCommunities = () => {
    communityDropdownApi(
      (res: any) => {
        dispatch({
          type: "Communities",
          payload: res.data,
        });
      },
      (res: any) =>
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
            severity: "error",
          },
        })
    );
  };

  useEffect(() => {
    communityDropdownApiAccess && fetchCommunities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityDropdownApiAccess]);

  return (
    <div>
      <Stack
        sx={{
          minHeight: "40rem",
        }}
        gap={2}
        flexDirection={"row"}
      >
        <UserList />
      </Stack>
    </div>
  );
};

export default Index;
