import {
  Autocomplete,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { useContext, useState } from "react";
import { CommunityTypes, UIContext, UIState } from "../../providers/UIProvider";
import { debounce, isNil } from "lodash";
import { userAdminSearchUser } from "../../apiCalls";
import { dispatchError } from "../../common/fx";

type Props = {
  setForm: (newForm: {
    communities: CommunityTypes[];
    role: any;
    user: any;
  }) => void;
  form: {
    communities: CommunityTypes[];
    role: any;
    user: any;
  };
};

const Step1 = ({ setForm, form }: Props) => {
  const [loadingBuyerName, setLoadingBuyerNames] = useState(false);
  const [userList, setUserList] = useState<any>([]);
  // const [loadingUserName, setLoadingUserName] = useState<any>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useContext<UIState | any>(UIContext);

  const searchAdminUsers = debounce((value: string) => {
    userAdminSearchUser(
      {
        search: value,
      },
      (res: any) => {
        console.log(res);
        setUserList(res.data);
        // setLoadingUserName(false);
      },
      (err: any) =>
        dispatch(
          dispatchError({
            message: err.message,
            statusText: err.response.statusText,
            title: err.response.data.title,
            status: err.response.status,
            detail: err.response.data.detail,
            data: err.response.data,
          })
        )
    );
  }, 1000);

  return (
    <Stack gap={2} mt={2} flexDirection={"column"}>
      <Autocomplete
        fullWidth
        multiple={false}
        loading={loadingBuyerName}
        size="medium"
        defaultValue={""}
        value={form?.user || null}
        onChange={(e, value) => {
          console.log(value);
          setForm({
            ...form,
            user: value,
          });
        }}
        onInputChange={(e, value) => {
          if (!isNil(value) && value.length >= 3) {
            if (e && e?.type !== "click") {
              setLoadingBuyerNames(true);
              searchAdminUsers(value);
            }
          }
        }}
        options={userList}
        filterOptions={(options: any[], state: any) => {
          let newOptions: any = [];
          // Escape special regex characters in the input value and convert to lower case
          const inputValue = state.inputValue
            .toLowerCase()
            .replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
          const regex = new RegExp(`\\b${inputValue}`, "i"); // 'i' for case insensitive

          options.forEach((element) => {
            let name = element?.fullName?.toLowerCase();
            // Check if any word starts with the input value
            if (regex.test(name)) {
              newOptions.push(element);
            }
          });
          return newOptions;
        }}
        renderOption={(props, item) => (
          <li {...props} key={item.fullName}>
            <ListItemText>{item.fullName}</ListItemText>
          </li>
        )}
        getOptionLabel={(option) => {
          return `${option.fullName}`;
        }}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="User" fullWidth />
        )}
      />

      <TextField
        fullWidth
        size="small"
        InputLabelProps={{ shrink: true }}
        value={form?.user?.userPrincipalName ?? ""}
        label="User Principal Name"
        variant="outlined"
        disabled
        placeholder="User Principal Name"
      />

      <TextField
        fullWidth
        InputLabelProps={{ shrink: true }}
        size="small"
        value={form?.user?.userObjectId}
        label="Object ID"
        variant="outlined"
        disabled
        placeholder="Object ID"
      />
    </Stack>
  );
};

export default Step1;
