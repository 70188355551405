import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import { Link as RLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  Stack,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  Switch,
  ListItemText,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CreateChangeOrder from "../../modals/CreateChangeOrder/CreateChangeOrder";
import { CommunityTypes, UIContext, UIState } from "../../providers/UIProvider";
import { communityDropdownApi } from "../../apiCalls";
import "./index.scss";
import { numberWithCommas } from "../../utils/formatMoney";
import { debounce, isEmpty } from "lodash";
import AltViewGrid from "./altViewGrid";
import PriceAdjustment from "../../modals/PriceAdjustment/PriceAdjustment";
import moment from "moment";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { filterOptions } from "../../utils/filterOptions";
import useAccessControl from "../../hooks/useAccessControl";
import HomeViewGrid from "./homeViewGrid";
import useLocalStorage from "../../hooks/useLocalStorage";

function Copyright(props: any) {
  const applicationPrId = process.env.REACT_APP_APPLICATION_PR_ID;
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ mt: (theme) => theme.spacing(12), mb: (theme) => theme.spacing(4) }}
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://highlandhomes.com/">
        Highland Homes
      </Link>{" "}
      {new Date().getFullYear()}
      {"."} <span>{applicationPrId}</span>
    </Typography>
  );
}

const theme = createTheme();

export default function Home() {
  const StartNewIntentionRight = useAccessControl(
    "Intention",
    "StartNewIntention"
  );

  const PriceAdjustmentRight = useAccessControl("Intention", "PriceAdjustment");
  const communityDropdownApiAccess = useAccessControl(
    "Community",
    "GetActiveCommunities"
  );
  const [showArrow, setShowArrow] = React.useState(false);
  const [viewMode, setViewMode] = React.useState<string>("main");
  const [state, dispatch] = React.useContext<UIState | any>(UIContext);

  const [communities, setCommunities] = useLocalStorage<any[]>(
    "communities",
    []
  );

  const [search, setSearch] = React.useState<string>("");
  const [page, setPage] = React.useState(0);

  // Create a debounced function that updates the search state
  const debouncedSetSearch = React.useMemo(
    () =>
      debounce((nextValue) => {
        setSearch(nextValue);
        setPage(0);
      }, 300), // 300 ms delay
    []
  );

  const handleSearchChange = (event: any) => {
    // Call the debounced function passing the current input value
    debouncedSetSearch(event.target.value);
  };

  React.useEffect(() => {
    if (window.innerWidth <= 1750) {
      setShowArrow(true);
      const timer = setTimeout(() => {
        setShowArrow(false);
      }, 5000); // Arrow disappears after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, []);

  const fetchCommunities = () => {
    communityDropdownApi(
      (res: any) => {
        dispatch({
          type: "Communities",
          payload: res.data,
        });
      },
      (res: any) =>
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
            severity: "error",
          },
        })
    );
  };

  React.useEffect(() => {
    communityDropdownApiAccess && fetchCommunities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityDropdownApiAccess]);

  const jobColumns: GridColDef[] = [
    {
      field: "lastUpdated",
      headerName: "Last Updated",
      flex: 1,
      width: 155,
      valueFormatter: (params) =>
        params.value ? `${moment(params.value).format("MM-DD-YYYY")}` : "",
      renderCell: (params) => (
        <Tooltip
          placement="right-start"
          title={`Last Updated On ${moment(params.row.lastUpdated).format(
            "MM-DD-YYYY"
          )} by  ${params.row.lastUpdatedByName} `}
        >
          <span className="table-cell-trucate">
            {moment(params.row.lastUpdated).format("MM-DD-YYYY")}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "jobKey",
      headerName: "Job Number",
      flex: 1,
      width: 150,
    },

    {
      field: "community",
      headerName: "Community",
      flex: 1,
      width: 200,
    },
    {
      field: "address",
      headerName: "Address",
      width: 300,
      renderCell: (params) => (
        <RLink
          to={{
            pathname: "/job-summary",
            search: `?addressid=${params.row.id}`,
          }}
        >
          {params.row.address}
        </RLink>
      ),
    },
    {
      field: "planNumber",
      headerName: "Plan",
      flex: 1,
      width: 100,
    },
    {
      field: "planElevationNumber",
      headerName: "Elevation",
      flex: 1,
      width: 130,
    },
    // {
    //   field: "type",
    //   headerName: "Type",
    //   flex: 1,
    // },
    {
      field: "buyerName",
      headerName: "Buyer",
      flex: 1,
      width: 125,
      renderCell: (params) => params.row.buyerName,
    },
    {
      field: "pendingChangeOrders",
      headerName: "Pending",
      flex: 1,
      width: 130,
    },
    {
      field: "submittedChangeOrders",
      headerName: "Submitted",
      flex: 1,
      width: 135,
    },
    {
      field: "newContractPrice",
      headerName: "Total Sales Price",
      flex: 1,
      width: 200,
      align: "right",
      headerAlign: "right",
      valueFormatter: (params) => `$ ${numberWithCommas(params.value)}`,
    },

    {
      field: "actions",
      headerName: "",
      align: "right",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      hideable: true,
      pinnable: false,
      editable: false,
      flex: 1,
      minWidth: 100,

      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0.5} mr={2}>
            <>
              {/* <Tooltip title={`Create Change Order For ${params.row.address}`}>
                <IconButton
                  onClick={() =>
                    startNewSubordinate(params.row.id, params.row.address)
                  }
                >
                  <AddCircleOutlineTwoTone />
                </IconButton>
              </Tooltip> */}
              <RLink
                to={{
                  pathname: "/job-summary",
                  search: `?addressid=${params.row.id}`,
                }}
              >
                <Tooltip title={`View Change Order For ${params.row.address}`}>
                  <IconButton>
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </RLink>
            </>
          </Stack>
        );
      },
    },
  ];

  const gridRef = React.useRef<HTMLDivElement>(null);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth={false}>
        <CssBaseline />
        <Stack
          mt={2}
          mb={4}
          gap={2}
          sx={{
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { xs: "center", md: "space-between" },
          }}
        >
          <Box
            sx={{
              maxWidth: {
                xs: "100%",
                md: "45rem",
              },
            }}
            width={"100%"}
            gap={2}
            display={"flex"}
            alignItems={"center"}
          >
            <TextField
              fullWidth
              size="small"
              label="Search"
              variant="outlined"
              onChange={handleSearchChange}
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="info" />
                  </InputAdornment>
                ),
              }}
            />

            <Autocomplete
              fullWidth
              multiple
              disableCloseOnSelect
              size="small"
              onChange={async (events, value) => {
                dispatch({
                  type: "CreateChangeOrderForm",
                  payload: {
                    community: value[0],
                  },
                });

                setPage(0);
                setCommunities(value);
              }}
              options={state.communities as CommunityTypes[]}
              getOptionLabel={(option) =>
                `${option.name} (${option.projectNumber})`
              }
              value={communities}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Community"
                  fullWidth
                />
              )}
              filterOptions={filterOptions}
              renderOption={(props, item) => (
                <li {...props} key={item.projectId}>
                  <ListItemText>{`${item.name} (${item.projectNumber})`}</ListItemText>
                </li>
              )}
            />
            <FormGroup
              sx={{
                width: "100%",
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={viewMode === "alt"}
                    disabled={isEmpty(state.availableJobs)}
                    onChange={(e, checked) =>
                      checked ? setViewMode("alt") : setViewMode("main")
                    }
                    inputProps={{ "aria-label": "Alternative View" }}
                  />
                }
                label="Alternative View"
              />
            </FormGroup>
          </Box>

          <Box
            width={"100%"}
            gap={2}
            sx={{
              display: "flex",
              maxWidth: {
                xs: "100%",
                md: "35rem",
              },
              alignItems: { xs: "space-between", md: "center" },
              justifyContent: { xs: "center", md: "flex-end" },
            }}
          >
            {StartNewIntentionRight && <CreateChangeOrder />}

            {PriceAdjustmentRight && <PriceAdjustment />}
          </Box>
        </Stack>
        {viewMode === "main" ? (
          <div ref={gridRef}>
            {showArrow && (
              <Box
                sx={{
                  position: "fixed",
                  right: 20,
                  bottom: "60%", // Adjust based on your layout
                  zIndex: 1000,

                  animation: "blink 1s linear infinite",
                  "@keyframes blink": {
                    "0%, 100%": { opacity: 1 },
                    "50%": { opacity: 0 },
                  },
                }}
              >
                <ArrowForwardIosIcon fontSize="large" />
              </Box>
            )}
            <HomeViewGrid
              setPage={setPage}
              page={page}
              searchText={search}
              communities={communities}
              jobColumns={jobColumns}
            />
          </div>
        ) : (
          <>
            <AltViewGrid
              searchText={search}
              communities={communities}
              jobColumns={jobColumns}
            />
          </>
        )}
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
