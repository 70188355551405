export const filterOptions = (options: any[], state: any) => {
  let newOptions: any = [];
  // Escape special regex characters in the input value and convert to lower case
  const inputValue = state.inputValue
    .toLowerCase()
    .replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  const regex = new RegExp(`\\b${inputValue}`, "i"); // 'i' for case insensitive

  options.forEach((element) => {
    let name = element?.name?.toLowerCase();
    // Check if any word starts with the input value
    if (regex.test(name)) {
      newOptions.push(element);
    }
  });
  return newOptions;
};
