import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";
import { useContext } from "react";
import {
  ElevationTypes,
  PlanTypes,
  UIContext,
  UIState,
} from "../../../providers/UIProvider";
import { isNil, isNull, uniqBy } from "lodash";
import { dispatchError } from "../../../common/fx";
import { getPlanElevations, UpdateElevation } from "../../../apiCalls";
import useAccessControl from "../../../hooks/useAccessControl";

const Step1 = ({
  setNewHomeInformation,
  newHomeInformation,
  setSpecBuyer,
  updateElevationMode,
}: any) => {
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const GetPlanElevationsAccess = useAccessControl("Plan", "GetPlanElevations");

  return (
    <Stack gap={2}>
      <Autocomplete
        defaultValue={""}
        clearIcon={null}
        onFocus={() => {
          if (state.createChangeOrderForm.customerBuyer === "Spec") {
            console.log(state.availableBuyerNames[0]);
            dispatch({
              type: "CreateChangeOrderForm",
              payload: {
                customerBuyer: state.availableBuyerNames[0],
                buyerType: "buyer",
              },
            });
          }
        }}
        disabled={true}
        fullWidth
        multiple={false}
        onChange={(e, value) => {
          console.log("onPlanChange", value);
          dispatch({
            type: "CreateChangeOrderForm",
            payload: {
              plan: value,
              elevation: "",
            },
          });
          if (!isNull(value)) {
            if (value.projectID) {
              console.log("plan", value);
              const { projectID, projectNumber, planId, planNumber } = value;
              GetPlanElevationsAccess &&
                getPlanElevations(
                  { projectID, projectNumber, planId, planNumber },
                  (res: any) => {
                    dispatch({
                      type: "AvailableElevations",
                      payload: res.data.sort(
                        (
                          a: {
                            planElevationNumber: string;
                          },
                          b: {
                            planElevationNumber: string;
                          }
                        ) =>
                          a.planElevationNumber.localeCompare(
                            b.planElevationNumber
                          )
                      ),
                    });
                  },
                  (err: any) =>
                    dispatch(
                      dispatchError({
                        message: err.message,
                        statusText: err.response.statusText,
                        title: err.response.data.title,
                        status: err.response.status,
                        detail: err.response.data.detail,
                        data: err.response.data,
                      })
                    )
                );
            }
          }
        }}
        onInputChange={(e, value, reason) => {
          if (!isNil(e)) {
            if (e.type !== "click") {
              dispatch({
                type: "CreateChangeOrderForm",
                payload: {
                  planOverride: value,
                },
              });
            }
          }
        }}
        options={uniqBy(state.availablePlans, (obj: PlanTypes) => {
          return obj.description;
        })}
        renderInput={(params: any) => (
          <TextField {...params} variant="outlined" label="Plan" fullWidth />
        )}
        size="medium"
        value={state.selectedJob.planNumber}
      />

      <Autocomplete
        multiple={false}
        fullWidth
        size="medium"
        clearIcon={null}
        defaultValue={""}
        value={newHomeInformation || null}
        onChange={(e, value) => {
          console.log(value);
          if (isNil(value)) {
            setNewHomeInformation({
              ...newHomeInformation,
              planElevationNumber: "",
            });
          } else
            setNewHomeInformation({
              ...newHomeInformation,
              ...value,
            });
        }}
        options={
          state.availableElevations.filter(
            (elevation: ElevationTypes) =>
              elevation.planElevationNumber !==
              state.selectedJob.planElevationNumber
          ) as ElevationTypes[]
        }
        getOptionLabel={(option) => `${option.planElevationNumber}`}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Elevation"
            helperText={"Select new elevation"}
            fullWidth
          />
        )}
      />
      {!updateElevationMode && (
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e, value) => {
                setSpecBuyer(value);
              }}
            />
          }
          label="Change buyer to Spec"
        />
      )}
    </Stack>
  );
};

export default Step1;
